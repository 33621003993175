import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classnames from 'classnames/bind';
import moment from 'moment';
import { getPrice } from 'utils/post';
import { createView, fetchItem } from 'state/reducers/postReducer';
import { getPaymentProfiles, setConnectingValue } from 'state/reducers/userReducer';
import { RootState } from 'state/store';

import Timer from 'components/Timer';
import { Post } from 'types';

import spinner from 'assets/images/at-anim-v2.gif';
import ItemActivity from 'routes/Item/ItemActivity';
import PurchaseButton from 'routes/Item//PurchaseButton';
import ItemCard from 'routes/Item/ItemCard';
import styles from 'routes/Item/Item.module.scss';

const cx = classnames.bind(styles);

const MarketplaceItem = () => {
  const { post, merchant } = useSelector((state: RootState) => ({
    post: state.post,
    merchant: state.merchant,
    user: state.user
  }));

  useEffect(() => {
    dispatch(setConnectingValue(false));
  }, [post.item]);

  const dispatch = useDispatch();
  const { guid } = useParams();

  const incrementViews = (post?: Post) => {
    const view = {
      merchantGUID: post?.merchantGUID,
      postGUID: post?.guid,
      dateViewed: moment().format('MM/DD/YY hh:mm:ss')
    };

    dispatch(createView(view));
  };

  useEffect(() => {
    if (guid) {
      dispatch(fetchItem(guid));
    }
  }, [guid]);

  useEffect(() => {
    incrementViews(post.item);
  }, [post.item]);

  useEffect(() => {
    dispatch(getPaymentProfiles());
  }, []);

  return (
    <>
      {post.item ? (
        <>
          <div className={cx('container', 'piece')}>
            <div className={cx('columns')}>
              <div className={cx('column')}>
                <div className={cx('serif', 'is-size-2', 'has-text-centered', 'has-text-weight-bold', 'mb-4', 'mt-6')}>
                  {post.item.name}
                </div>
                <ItemCard item={post.item} />
                <div className={cx('flexbox', 'justify-between', 'px-4', 'mt-2')}>
                  <div>Blockchain</div>
                  <div>{post.nftInformation?.blockchainType || 'Ethereum'}</div>
                </div>
              </div>
              <div className={cx('column')}>
                <div className={cx('flexbox', 'height-full')}>
                  <div className={cx('caustic', 'has-text-centered', 'pricing-padding')}>
                    {!post.item.marketplaceListingGUID && (
                      <div className={cx('no-sale', 'mt-4')}>This item has been already sold.</div>
                    )}
                    {post.item.marketplaceListingGUID && (
                      <>
                        <div className={cx('pricing', 'mt-4')}>
                          <div className={cx('flex')}>
                            <div className={cx('has-text-muted')}>
                              {post.viewMarketPlaceItem?.priceType === 'BestOffer' ? 'Highest bid' : 'Donation Amount'}
                            </div>
                            <div className={cx('has-text-weight-bold', 'is-size-1')}>
                              {getPrice(post.item)[0] === 'usd' ? getPrice(post.item)[1] : getPrice(post.item)[2]}
                            </div>
                            {/*<div className={cx('has-text-weight-semibold', 'has-text-muted', 'is-size-5', 'mb-5')}>
                          </div>*/}
                          </div>
                          {post.viewMarketPlaceItem?.priceType === 'BestOffer' && (
                            <div className={cx('flex', 'ml-4')}>
                              <div className={cx('has-text-muted')}>Bid ends</div>
                              <div className={cx('flexbox')}>
                                <div className={cx('mx-auto')}>
                                  <Timer type={2} date={post.viewMarketPlaceItem.expirationDate as Date} />
                                </div>
                              </div>
                              <div className={cx('has-text-weight-semibold', 'has-text-muted')}>
                                {moment(post.viewMarketPlaceItem.expirationDate).format('LL, hA')}
                              </div>
                            </div>
                          )}
                        </div>
                        <PurchaseButton currency={getPrice(post.item)[0] === 'usd' ? 'USD' : 'ETH'} />
                      </>
                    )}
                    <div className={cx('has-text-justified')}>
                      {post.item.description || post.item.descriptionShort}
                    </div>
                    <div className={cx('section')}>
                      <div className="has-text-left has-text-weight-semibold has-text-muted is-size-6 mb-2">
                        Item Activity
                      </div>
                      <ItemActivity />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx('columns')}>
              <div className={cx('column')}>
                {(post.item.backstoryVideoUrl || post.item.backstoryDescription !== '') && (
                  <div className={cx('section')}>
                    <div className={cx('has-text-weight-semibold', 'has-text-muted', 'is-size-6')}>About the Item</div>
                    <div className={cx('mt-2', 'mx-4')}>
                      {post.item.backstoryVideoUrl && (
                        <video
                          className={cx('player', 'mb-2')}
                          src={post.item.backstoryVideoUrl}
                          controls
                          controlsList="nodownload"
                          loop
                        />
                      )}
                      <div>{post.item.backstoryDescription}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className={cx('column')}>
                {post.item.nftBenefits && post.item.nftBenefits.length > 0 && (
                  <div className={cx('section')}>
                    <div className={cx('header')}>Benefits</div>
                    <div className={cx('content')}>
                      <ul className={cx('classic-list', 'check')}>
                        {post.item.nftBenefits?.map((benefit) => (
                          <li>{benefit}</li>
                        ))}
                      </ul>
                      <p className={cx('is-size-7')}>
                        Legal notice: While the creator makes every effort to fulfill these benefits, there is no
                        guarantee that they will complete it and you should NOT make a purchase based on the benefits
                        alone.
                      </p>
                      <div>
                        <img className={cx('signature')} src={merchant.merchant?.signatureImageUrl} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={cx('full-spinner')}>
          <img src={spinner} />
        </div>
      )}
    </>
  );
};

export default MarketplaceItem;
