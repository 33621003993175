import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GENERAL_ERR_MSG } from 'utils/copies';
import { Post } from 'types';

export const fetchMarketplaceItems = createAsyncThunk('marketplace/list', async () => {
  const resp = await httpClient.get(`/posts/search?hasActiveMarketplaceListing=true`);
  const posts = resp.data as Post[];

  return posts.filter((post) => post?.lastSoldMarketplaceListingSellerUserGUID);
});

interface MarketPlaceState {
  status: string;
  error?: string;
  data: Post[];
}

const initialState: MarketPlaceState = {
  status: 'idle',
  data: []
};

const marketPlaceSlice = createSlice({
  name: 'marketPlace',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMarketplaceItems.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(fetchMarketplaceItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMarketplaceItems.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action?.error?.message || GENERAL_ERR_MSG;
      });
  }
});

export const { reset } = marketPlaceSlice.actions;
export default marketPlaceSlice.reducer;
