import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, SubmitHandler } from 'react-hook-form';
import classnames from 'classnames/bind';
import Verification from 'components/Verification';
import { RegisterRequest } from 'types';
import { RootState } from 'state/store';
import { registerUser, resetLoginError, resetRegisterError, setCacheUser } from 'state/reducers/sessionReducer';
import { COUNTRIES } from 'consts/countries';

import Checkbox from 'components/Checkbox';
import styles from './Register.module.scss';

const cx = classnames.bind(styles);

export type RegisterProps = {
  type: 'mod' | 'red';
  onChange: (p: 'login') => void;
};

const Register = (props: RegisterProps) => {
  const { session } = useSelector((state: RootState) => ({
    session: state.session
  }));
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RegisterRequest>();
  const dispatch = useDispatch();
  const [isAcceptCardTerms, setIsAcceptCardTerms] = useState(false);

  const countries = COUNTRIES.map((x) => ({ name: `${x.code} (${x.dialCode})`, value: x.dialCode, code: x.code }));

  const onSubmit: SubmitHandler<RegisterRequest> = (data) => {
    dispatch(resetRegisterError());
    dispatch(resetLoginError());
    data.phoneNumber = `${data.countryCode}${data.phoneNumber}`;
    delete data.countryCode;
    const cacheUser = { username: data.username, password: data.password };
    dispatch(setCacheUser(cacheUser));
    dispatch(registerUser(data));
  };

  if (session.isRegisterSuccess) {
    return <Verification />;
  }

  return (
    <>
      {/*session.isRegisterSuccess ? (
        <div className={cx('has-text-centered')}>
          <div className={cx('is-size-4', 'has-text-success', 'mb-4')}>Registration Successful</div>
          <div>
            You have successfully registered. Please check your inbox to verify your email address. Once your email
            address is verified, you can log in.
          </div>
          <div className={cx('is-size-6', 'mt-2')} onClick={() => navigate('/account-verification')}>
            <span className={cx('is-link')}>Go to verification page</span>
          </div>
        </div>
      ) : (*/}
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4 className="is-size-3 has-text-weight-bold mb-3">Create Account</h4>
        <p className="is-size-6">Please create an account before making your donation.</p>
        <div className={cx('is-flex')}>
          <div className={cx('field', 'mr-4')}>
            <label className={cx('label')}>Country:</label>
            <select
              className={cx('input', 'quarter-width', { 'is-danger': errors.countryCode })}
              {...register('countryCode', { required: true })}>
              {countries?.map((option) => (
                <option key={option.name} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className={cx('field', 'is-flex-grow-1')}>
            <label className={cx('label')}>Phone number:</label>
            <div className={cx('control')}>
              <input
                className={cx('input', { 'is-danger': errors.phoneNumber })}
                type="number"
                {...register('phoneNumber', { required: true })}
              />
            </div>
          </div>
        </div>
        <div className={cx('field')}>
          <label className={cx('label')}>Username: (what you will sign in as)</label>
          <div className={cx('control')}>
            <input
              className={cx('input', { 'is-danger': errors.username })}
              {...register('username', { required: true })}
            />
          </div>
        </div>
        <div className={cx('field')}>
          <label className={cx('label')}>Email Address:</label>
          <div className={cx('control')}>
            <input
              className={cx('input', { 'is-danger': errors.emailAddress })}
              type="email"
              {...register('emailAddress', { required: true })}
            />
          </div>
        </div>
        <div className={cx('field')}>
          <label className={cx('label')}>Full Name:</label>
          <div className={cx('control')}>
            <input
              className={cx('input', { 'is-danger': errors.displayName })}
              {...register('displayName', { required: true })}
            />
          </div>
        </div>
        <div className={cx('field')}>
          <label className={cx('label')}>Password:</label>
          <div className={cx('control')}>
            <input
              className={cx('input', { 'is-danger': errors.password })}
              type="password"
              {...register('password', {
                required: true,
                pattern: {
                  value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                  message: 'Invalid password combination'
                }
              })}
            />
          </div>
          <div className={cx('is-size-7', 'has-text-muted', 'mt-1', { 'has-text-danger': errors.password })}>
            Password must be at least 8 characters and contain at least 1 uppercase and lowercase letter, and 1 number.
          </div>
        </div>
        <div className={cx('has-text-muted', 'mb-5')}>
          <Checkbox name="accept" onChange={(e) => setIsAcceptCardTerms(e)} termsPrivacy={true} />
        </div>
        <div className="field">
          <button
            type="submit"
            className={cx('button', 'is-fullwidth', {
              'is-loading': session.status === 'pending'
            })}
            disabled={!isAcceptCardTerms}>
            Continue
          </button>
        </div>
        {session.registerError && (
          <div className={cx('has-text-centered', 'has-text-danger', 'is-size-7', 'mb-2')}>{session.registerError}</div>
        )}
        <div className={cx('is-link', 'has-text-right', 'mt-6')} onClick={() => props.onChange('login')}>
          Already have an account? Sign in here.
        </div>
      </form>
    </>
  );
};

export default Register;
