import { httpClient } from 'http/httpClient';
import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { LoginRequest, RegisterRequest, UserResetPassword } from 'types';
import { ErrorPayload } from 'types/errorPayload';

export const registerUser = createAsyncThunk('session/register', async (data: RegisterRequest) => {
  data.primaryPlatform = process.env.REACT_APP_PRIMARY_PLATFORM;
  //data.username = data.emailAddress;
  const response = await httpClient.post('/users/register', data);
  return response.data;
});

export const loginUser = createAsyncThunk('session/login', async (data: LoginRequest, { rejectWithValue }) => {
  data.platform = process.env.REACT_APP_PRIMARY_PLATFORM;
  try {
    const response = await httpClient.post('/users/login/password', data);
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    // @TODO - fix hacky solution

    return rejectWithValue(err.response.data);
  }
});

export const verifyByPhoneNumber = createAsyncThunk(
  'auth/verifyPhoneNumber',
  async (data: { guid: string; code: number | string }, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(`/users/${data.guid}/phonenumber/verify`, {
        verificationCode: data.code
      });
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      // @TODO - fix hacky solution
      return rejectWithValue(err.response.data);
    }
  }
);

export const resendPhoneVerification = createAsyncThunk('auth/resendVerifyPhoneNumber', async (guid: string) => {
  const response = await httpClient.post(`/users/${guid}/phonenumber/verify/resend`, {});
  return response.data;
});

export const verifyByEmail = createAsyncThunk(
  'auth/verifyEmail',
  async (data: { guid: string; code: number | string }, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(`/users/${data.guid}/email/verify`, {
        verificationCode: data.code
      });
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      // @TODO - fix hacky solution
      return rejectWithValue(err.response.data);
    }
  }
);

export const resendEmailVerification = createAsyncThunk('auth/resendVerifyEmail', async (guid: string) => {
  const response = await httpClient.post(`/users/${guid}/email/verify/resend`, {});
  return response.data;
});

export const passwordReset = createAsyncThunk('user/passwordReset', async (payload: UserResetPassword) => {
  payload.platform = process.env.REACT_APP_PRIMARY_PLATFORM;
  payload.method = 'email';
  const response = await httpClient.post('/users/password/reset/init', payload);
  return response.data;
});

export const verifyPasswordReset = createAsyncThunk('auth/verifyPasswordReset', async (payload: UserResetPassword) => {
  const response = await httpClient.post('/users/password/reset', payload);
  return response.data;
});

export interface SessionState {
  status: string;
  loginError?: string;
  registerError?: string;
  authToken?: string;
  isRegisterSuccess?: boolean;
  lastVisted?: string;

  isPasswordTokenSent?: boolean;
  isPasswordResetVerified?: boolean;
  isPasswordChanged?: boolean;
  isRegistered?: boolean;
  isVerified?: boolean;
  guid?: string;
  verificationCode?: string;
  errorPayload?: ErrorPayload;
  cacheUser?: LoginRequest;
}

const initialState: SessionState = {
  status: 'idle'
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    logout: () => initialState,
    resetLoginError: (state) => {
      state.loginError = undefined;
    },
    resetRegisterError: (state) => {
      state.registerError = undefined;
    },
    setUnverifiedAccount: (state, action) => {
      state.guid = action.payload.guid;
      state.isRegistered = action.payload.isRegistered;
      state.isVerified = false;
    },
    setCacheUser: (state, action) => {
      state.cacheUser = action.payload;
    },
    resetCacheUser: (state) => {
      state.cacheUser = undefined;
    },
    resetAuthToken: (state) => {
      state.authToken = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.isRegisterSuccess = true;
        state.guid = action.payload.guid;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.authToken = action.payload.token;
        state.cacheUser = undefined;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = 'rejected';
        state.registerError = action.error.message;
        state.errorPayload = action.payload as ErrorPayload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        const errorPayload = action.payload as ErrorPayload;
        state.status = 'rejected';
        state.errorPayload = errorPayload;
        const message = errorPayload.message ? errorPayload.message : 'The username/password you entered is invalid.';
        state.loginError = message;
      })
      .addCase(verifyByPhoneNumber.fulfilled, (state, action) => {
        state.status = 'success';
        state.isRegistered = true;
        state.isVerified = true;
        state.verificationCode = action.payload.verificationCode;
      })
      .addCase(verifyByPhoneNumber.rejected, (state) => {
        state.status = 'success';
        state.isVerified = false;
        state.registerError = 'Verification failed.';
      })
      .addCase(verifyByEmail.fulfilled, (state, action) => {
        state.status = 'success';
        state.isRegistered = true;
        state.isVerified = true;
        state.verificationCode = action.payload.verificationCode;
      })
      .addCase(passwordReset.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isPasswordTokenSent = true;
      })
      .addCase(verifyPasswordReset.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isPasswordResetVerified = true;
      })
      .addMatcher(
        (action): action is AnyAction => /session\/(.*)\/pending/.test(action.type),
        (state) => {
          state.status = 'pending';
        }
      );
  }
});

export const {
  logout,
  resetLoginError,
  resetRegisterError,
  setUnverifiedAccount,
  setCacheUser,
  resetCacheUser,
  resetAuthToken
} = sessionSlice.actions;
export default sessionSlice.reducer;
