import React from 'react';
import classnames from 'classnames/bind';

import header from 'assets/images/header.png';
import building from 'assets/images/building.png';
import gretchen from 'assets/images/gretchen.jpg';
import ryon from 'assets/images/ryon.jpg';
import lenisha from 'assets/images/lenisha.jpg';
import nationalCore from 'assets/images/national-core.png';
import hopeHousing from 'assets/images/hope-through-housing.png';
import united24Text from 'assets/images/united24-text.png';
import countOnUsVideo from 'assets/videos/countonus_final.mp4';

import NFTDonations from './NFTDonations';
import styles from './NewNiftyHome.module.scss';

const cx = classnames.bind(styles);

const NewNiftyHome = () => {
  return (
    <div>
      <div className={cx('header')}>
        <img className={cx('header-img')} src={header} />
        <div className={cx('header-text')}>
          <div className={cx('a')}>HELP</div>
          <div className={cx('b')}>REBUILD UKRAINE</div>
        </div>
        <div className={cx('flag')}>
          {/* <div className={cx('flag-inner')}>
            <img src={countOnUs} />
            <iframe
              height="315"
              src="https://www.youtube.com/embed/adLGHcj_fmA?controls=0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          </div> */}
        </div>
      </div>
      <div className={cx('main')}>
        <div className={cx('container', 'mb-6')}>
          <div className={cx('has-text-weight-bold', 'has-text-centered', 'is-size-2')}>Count On Us!</div>
          <div>
            <p className={cx('has-text-centered', 'is-size-4')}>
              We have the power to change the world! Donate today and let the Ukrainian people know that they Count on
              Us!
            </p>
            <p className={cx('is-size-6')}>
              Since February 2022, Ukraine has suffered considerable damage and the loss of thousands of civilian lives.
              Daily, cities in Ukraine are under attack, and more than 14 million Ukrainians have fled their homes,
              including more than six million who have left for neighboring countries, according to the United Nations.
              Your donation today will help the Ukrainian people rebuild their lives at home and abroad.
            </p>
          </div>
          {/* <div className={cx('has-text-centered')}>
            <button className={cx('button', 'button-nifty', 'is-black', 'my-6')}>
              Download original artwork and song
            </button>
          </div> */}
          <div className={cx('container', 'has-text-centered', 'mb-6')}>
            <div className={cx('has-text-weight-bold', 'is-size-2')}>Donate Now</div>
            <div className={cx('is-size-5', 'has-text-centered', 'mb-5')}>
              Receive a commemorative NFT in recognition of your support. All donations are 100% tax deductible.
            </div>
            <div>
              <video controls src={countOnUsVideo} className={cx('mx-auto')} />
            </div>
          </div>
          {/* <div className={cx('mb-4', 'mt-2')}>
            To make a donation, you will be asked to create a Count On Us account. This is where your commemorative NFT
            (non-fungible token) is stored. All donations are 100% tax-deductible.
          </div> */}
        </div>

        <div className={cx('container', 'mb-6')}>
          <div className="columns">
            {/*<div className="column" />
                <div className="column is-one-third">
              <CreditCardDonation />
            </div>*/}
            <div className="column is-two-thirds mx-auto">
              <NFTDonations />
            </div>
          </div>
        </div>
        {/*<div className={cx('container', 'mb-6')}>
          <div>
            <video controls src={countOnUsVideo} className={cx('mx-auto')} />
          </div>
        </div>*/}

        <div className={cx('container', 'mb-6')}>
          <div className={cx('has-text-weight-bold', 'has-text-centered', 'is-size-2')}>Charity Partner</div>
          <div className={cx('flexbox', 'mx-6', 'mt-4', 'wrap', '_LGaYj')}>
            <div className={cx('flex')}>
              <img className={cx('charity-logo', 'mx-auto')} src={united24Text} />
            </div>
            <div className={cx('flex', 'mt-4', 'is-size-6')}>
              United24 was launched by President Zelenskyy as the main venue for collecting charitable donations in
              support of Ukraine. All funding to United24 from the Count on Us Campaign will be directed to the Rebuild
              Ukraine initiative, supporting the future of Ukraine.
            </div>
          </div>
        </div>

        <div className={cx('container', 'mb-6')}>
          <div className={cx('circle', 'has-text-centered', 'is-size-2')}>Contributing Artists</div>
          <p className={cx('is-size-6')}>
            The Count on Us Campaign has combined the power of music with the emotion of art to create a one-of-a-kind
            campaign, collaborating with musicians, artists, and content creators, minting NFTs, creating original
            music, and offering an exclusive metaverse experience to be launched in early Fall.
          </p>
          <div className={cx('container')}>
            <div className={cx('columns')}>
              <div className={cx('column')}>
                <div className={cx('bru')}>
                  <img className={cx('artist')} src={gretchen} />
                  <div className={cx('circle', 'small', 'has-text-centered', 'mb-4')}>Gretchen Fauske</div>
                  <div className={cx('is-size-6')}>
                    The Count on Us Campaign is the launch of Gretchen Fauske as a bona fide NFT artist, using her
                    talent to bring attention to the humanitarian crisis in Ukraine. Each layer of the original image,
                    from the purple mountain majesties to the fighter jets flying east, speaks to our anthem that the
                    Ukrainian people can Count on Us! She revels in creative collaboration, and focuses her work on
                    social issues and celebrating community. Inspired by stories of the courage and resiliency of the
                    Ukrainian people in the face of heartbreaking atrocities, Gretchen hopes that her artwork, in
                    combination with the lyrics from the original composition, will speak to the world and encourage
                    others to help.
                  </div>
                </div>
              </div>

              <div className={cx('column')}>
                <div className={cx('bru')}>
                  <img className={cx('artist')} src={ryon} />
                  <div className={cx('circle', 'small', 'has-text-centered', 'mb-4')}>Ryon Cali</div>
                  <div className={cx('is-size-6')}>
                    In collaboration with Jeff Burum, co-founder of National CORE, Ryon has created a powerful anthem
                    using famous passages and inspirational music from the past. Ryon has honed his skills by securing
                    sync placements, performing on the world’s biggest poetry stages, writing for solo artists and
                    groups, and working with a few of the industry’s best producers. The Los Angeles native has been
                    able to work with Grammy award-winning and Grammy-nominated producers. He has a unique tone, and
                    creative lyricism, bringing a positive spirit to the hip-hop/rap genre.
                  </div>
                </div>
              </div>

              <div className={cx('column')}>
                <div className={cx('bru')}>
                  <img className={cx('artist')} src={lenisha} />
                  <div className={cx('circle', 'small', 'has-text-centered', 'mb-4')}>Lenisha Nelson</div>
                  <div className={cx('is-size-6')}>
                    Lush, Smooth, and Soulful… are just a few of the words used to define this accomplished vocalist’s
                    distinctive approach to Soul and R&B. She has taken personal experiences in love, loss, betrayal,
                    happiness, and insecurity to convey heartfelt emotion as only she can through her distinctive voice
                    and her expressive words. Gritty, Real, Deep…Lenisha taps into the hearts and souls of women
                    everywhere, sharing her experiences in life and love, and honoring women. Her songs reveal raw
                    emotions that all women experience, and she is not afraid to explore and express those feelings in
                    her songwriting. Her musical influences include Faith Evans, Mary J. Blige, and Alicia Keys.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className={cx('container', 'mb-6')}>
          <div className={cx('has-text-weight-bold', 'has-text-centered', 'is-size-2')}>About Your Donation</div>
          <div className={cx('mt-4', 'mx-6', 'is-size-6')}>
            <p>
              <b>
                Your contribution today will support thousands of children, families, and seniors during this
                humanitarian crisis and help facilitate resiliency over the coming months.
              </b>
            </p>
            <p>
              The Count on Us Foundation has partnered with United 24, an initiative of President Zelenskyy, and its
              official partner in the USA, Ukraine House DC Foundation, to Rebuild Ukraine. All proceeds will support
              the immediate needs of the Ukrainian people and their efforts to find housing and rebuild their lives.
            </p>
          </div>
          <div className={cx('has-text-weight-bold', 'has-text-centered', 'is-size-6')}>Tax ID Information</div>
          <div className={cx('mt-4', 'mx-6')}>
            <p className={cx('is-size-6')}>
              Count on Us is an independent 501(c)3 and all donations are tax-deductible to the extent of IRS
              regulations. Tax ID: 82-0823845
            </p>
          </div>
        </div> */}

        {/* <div className={cx('container', 'mb-6')}>
          <div className={cx('flexbox', 'mx-6')}>
            <div className={cx('flex')}>
              <div>
                <img className={cx('charity-logo', 'mx-auto')} src={charity3} />
              </div>
            </div>
            <div className={cx('flex')}>
              <div>
                <div className={cx('has-text-weight-bold', 'is-size-6')}>
                  Building Web3 for Charities and Non-Profits.
                </div>
                <div className={cx('mt-4', 'is-size-6')}>
                  Charity3 is bridging the gap between conventional forms of fundraising and the web3 opportunity.
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className={cx('mission')}>
          <div className={cx('container')}>
            <div className={cx('columns', 'reverse')}>
              <div className={cx('column', 'p-0')}>
                <img className={cx('building')} src={building} />
              </div>

              <div className={cx('column')}>
                <img className={cx('artist')} />
                <div className={cx('is-size-2', 'has-text-weight-bold', 'my-4')}>Count on Us Foundation</div>
                <p className={cx('is-size-6')}>
                  Established to provide a fundraising platform to provide humanitarian aid in times of crisis. The
                  Count on Us Foundation is part of the National CORE and Hope through Housing Foundation family of
                  nonprofits.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={cx('organizers', 'mt-6')}>
          <div className={cx('container')}>
            <div className={cx('circle', 'is-size-2')}>Non-Profit Partners</div>
            <div className={cx('flexbox', 'organize', 'mt-4')}>
              <img src={nationalCore} className={cx('mr-4')} />
              <div className={cx('text')}>
                <div className={cx('circle', 'small', 'is-size-3')}>National CORE</div>
                <p className={cx('is-size-6')}>
                  National CORE, based in Southern California, is one of the nation’s largest nonprofit developers of
                  affordable communities, managing approximately 10,000 apartment homes in California, Texas, and
                  Florida. National CORE’s strength is its ability to offer partners an array of in-house capabilities
                  that navigates the complexities of planning, developing, building, managing, and providing supportive
                  services. For more information on National CORE, please visit www.nationalcore.org
                </p>
                <div>
                  <a
                    className={cx('button', 'button-nifty', 'is-blue', 'my-4')}
                    href="https://www.nationalcore.org"
                    target="_blank">
                    LEARN MORE ABOUT NATIONAL CORE
                  </a>
                </div>
              </div>
            </div>
            <div className={cx('flexbox', 'organize', 'reverse', 'mt-4', 'mb-6')}>
              <div className={cx('text')}>
                <div className={cx('circle', 'small', 'is-size-3')}>Hope Through Housing</div>
                <p className={cx('is-size-6')}>
                  We believe that both people and place matter in achieving community-wide well-being. Hope through
                  Housing maintains an unwavering commitment to serving as a collaborative platform for collective
                  investment and impact in neighborhoods of poverty, crime, blight, and isolation. For more information
                  on Hope through Housing, please visit www.hthf.org.
                </p>
                <div>
                  <a
                    className={cx('button', 'button-nifty', 'is-blue', 'my-4', 'fix-long')}
                    href="https://www.hthf.org"
                    target="_blank">
                    LEARN MORE ABOUT HOPE THROUGH HOUSING
                  </a>
                </div>
              </div>
              <img src={hopeHousing} className={cx('ml-4')} />
            </div>

            {/*<div className={cx('mt-4')}>
              <div className={cx('circle', 'has-text-centered')}>Partner Charity</div>
              <div className={cx('partners')}>
                <div className={cx('flexbox', 'partner')}>
                  <img className={cx('partner-height')} src={united24} />
                </div>
              </div>
            </div>*/}
          </div>
        </div>

        {/*<div className={cx('united')}>
          <img className={cx('zelenskyy')} src={zelenskyy} />
          <div className={cx('container', 'z-1')}>
            <div className={cx('is-size-3', 'has-text-weight-bold')}>
              The Global Initiative of the President of the Ukraine
            </div>
            <a className={cx('button', 'button-nifty', 'is-black', 'my-4')} href="/assets/U24.pdf" target="_blank">
              Learn more about how your donation will be used
            </a>
          </div>
        </div>

        <div className={cx('raised')}>
          <div className={cx('title')}>Total Raised</div>
          <div className={cx('amount')}>$1,875,550</div>
        </div>

        <div className={cx('about')}>
          <div className={cx('container')}>
            <div className={cx('circle', 'small')}>About your donation</div>
            <p className={cx('has-text-weight-bold')}>
              Your contribution today will support thousands of children, families, and seniors during this humanitarian
              crisis and help facilitate resiliency over the coming months.
            </p>
            <p>
              The Count on Us Campaign will collaborate with other charities worldwide to guarantee that all proceeds go
              to the immediate needs of the Ukrainian people and their efforts to rebuild their homeland.
            </p>
          </div>
        </div>*/}
      </div>
    </div>
  );
};

export default NewNiftyHome;
