import axios, { AxiosInstance } from 'axios';
import { store } from 'state/store';
import localForage from 'localforage';

const APPLICATION_JSON = 'application/json';
const AUTH_HEADER = 'authorization';
const CONTENT_TYPE = 'content-type';
const UNAUTHORIZED = 401;

const getAuthHeader = () => {
  const state = store.getState();
  const token = state.session.authToken;
  return { [AUTH_HEADER]: token };
};

const applyDefaultInterceptors = (client: AxiosInstance) => {
  client.interceptors.request.use((config) => {
    const authHeader = getAuthHeader();
    const { headers } = config;
    config.headers = {
      ...headers,
      ...authHeader
    };
    return config;
  });

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === UNAUTHORIZED) {
        localForage.clear();
        window.location.replace('/login?session-expired=true');
      }

      const checkResponseUrl = error.response.request.responseURL as string;

      if (checkResponseUrl !== undefined && checkResponseUrl.indexOf('login/password') !== -1) {
        return Promise.reject(error);
      }
      return Promise.reject(error.response?.data?.message);
    }
  );
};

const configureHttpClient = (client: AxiosInstance) => {
  applyDefaultInterceptors(client);
  return client;
};

const initializeHttpClient = (baseUrl?: string) => {
  const client = axios.create({
    baseURL: baseUrl,
    headers: {
      Accept: APPLICATION_JSON,
      [CONTENT_TYPE]: APPLICATION_JSON
    }
  });
  return configureHttpClient(client);
};

export const httpClient = initializeHttpClient(process.env.REACT_APP_BASE_API_URL);
export const httpClientFeaturedPost = initializeHttpClient(process.env.REACT_APP_FEATURED_API_URL);
