import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import classnames from 'classnames/bind';

import { UserResetPassword } from 'types';
import { verifyPasswordReset } from 'state/reducers/sessionReducer';
import { RootState } from 'state/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { openSnackbar } from 'state/reducers/snackbarReducer';
import styles from './PasswordReset.module.scss';

const cx = classnames.bind(styles);

const PasswordReset = () => {
  const { session } = useSelector((state: RootState) => ({
    session: state.session
  }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [verifyStatus, setVerifyStatus] = useState(0);
  const [verificationCode, setVerificationCode] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserResetPassword>();

  const onVerifyPasswordReset = (data: UserResetPassword) => {
    if (data.newPassword !== data.confirmPassword) {
      setVerifyStatus(1);
    } else {
      data.emailVerificationCode = verificationCode;
      dispatch(verifyPasswordReset(data));
    }
  };

  useEffect(() => {
    if (session.isPasswordResetVerified) {
      setVerifyStatus(0);
      setVerificationCode('');
      dispatch(
        openSnackbar({
          message: 'Password reset successful! Redirecting to login page...',
          type: 'success'
        })
      );
      setTimeout(() => {
        navigate('/login');
      }, 4000);
    }
  }, [session.isPasswordResetVerified]);

  useEffect(() => {
    if (location.pathname === '/verify') {
      setVerificationCode(new URLSearchParams(location.search).get('verificationCode') || '');
    }
  }, []);

  return (
    <div className={cx('container', 'stretch')}>
      <div className={cx('wrapper')}>
        <div className={cx('login')}>
          <div className={cx('is-size-3', 'has-text-weight-semibold', 'mb-4')}>Reset your Password</div>
          <form onSubmit={handleSubmit(onVerifyPasswordReset)}>
            <div className={cx('field')}>
              <label className={cx('label')}>New password</label>
              <div className={cx('control')}>
                <input
                  type="password"
                  className={cx('input', { 'is-danger': errors.newPassword })}
                  {...register('newPassword', { required: true })}
                />
              </div>
            </div>

            <div className={cx('field')}>
              <label className={cx('label')}>Confirm new password</label>
              <div className={cx('control')}>
                <input
                  type="password"
                  className={cx('input', { 'is-danger': errors.confirmPassword })}
                  {...register('confirmPassword', { required: true })}
                />
              </div>
            </div>

            <div className="field">
              <button
                className={cx('button', 'is-fullwidth', {
                  'is-loading': session.status === 'pending'
                })}>
                Submit
              </button>
            </div>

            {verifyStatus === 1 && (
              <div className={cx('has-text-centered', 'has-text-danger', 'is-size-7')}>Passwords do not match.</div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
