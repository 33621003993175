import React from 'react';
import classnames from 'classnames/bind';
import { Post } from 'types';
import styles from './PostItem.module.scss';

const cx = classnames.bind(styles);

export type PostItemProps = {
  post: Post;
  viewPiece: (post: Post) => void;
};

const PostItem: React.FC<PostItemProps> = ({ viewPiece, post }) => {
  return (
    <div className="trance-card" onClick={() => viewPiece(post)}>
      <div className="top">
        {post.postType === 'Image' ? (
          <img className="thumbnail" src={post.contentUrl || post.thumbnailImageUrl} />
        ) : (
          <img className="thumbnail" src={post.thumbnailImageUrl} />
        )}
      </div>

      <div className={cx('bottom')}>
        <div className={cx('has-text-left', 'is-flex-grow-1')}>
          {/* <div className={cx('is-size-7')}>Price</div> */}
          <div className={cx('mobi', 'is-size-3')}>
            {post?.marketplaceListingUSDAmount ? `$${post.marketplaceListingUSDAmount}` : ''}
          </div>
        </div>
        <div className={cx('has-text-right')}>
          {/* <div className={cx('is-size-7')}>Price</div> */}
          <div className={cx('is-size-5')}>
            {post?.marketplaceListingETHAmount ? `${post.marketplaceListingETHAmount.toFixed(3)} ETH` : ''}
          </div>
        </div>
        {/* <div className={cx('mx-auto')}>
          <div className={cx('is-size-7')}>Owner</div>
          <div className={cx('has-text-weight-bold', 'is-size-6')}>
            <div className={cx('seller-profile')}>
              <img className={cx('seller-profile-img')} src={post.marketplaceListingSellerUserProfileImageUrl} />
              <span>{post.marketplaceListingSellerUserDisplayName}</span>
            </div>
          </div>
        </div>
        <div className={cx('has-text-right')}>
          <div className={cx('is-size-7')}>Views</div>
          <div className={cx('has-text-weight-bold', 'is-size-6')}>{post.viewCount}</div>
        </div> */}
      </div>
    </div>
  );
};

export default PostItem;
