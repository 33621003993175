import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import HomeBase from 'routes/HomeBase';

import Landing from 'routes/Landing/Landing';

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/landing" element={<Landing />} />
          <Route path="/*" element={<HomeBase />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
