import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Post } from 'types';
import { setPopupState } from 'state/reducers/userReducer';
import classnames from 'classnames/bind';
import { RootState } from 'state/store';
import { fetchCollectionByMerchantUserGUID } from 'state/reducers/merchantReducer';
import Skeleton from 'components/Skeleton';

import cryptos from 'assets/images/cryptos.png';
import coinbase from 'assets/images/coinbase logo.png';

import CreditCardDonation from './CreditCardDonation';

import styles from './Donation.module.scss';

const cx = classnames.bind(styles);

const NFTDonations = () => {
  const { merchant } = useSelector((state: RootState) => ({
    merchant: state.merchant
  }));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [donateConfirmed, setDonateConfirmed] = useState(false);
  const [showCreditDonation, setShowCreditDonation] = useState(false);

  // const [donateType, setDonateType] = useState(0);
  const don = useRef('');
  const { loggedIn, isRegisterSuccess } = useSelector((state: RootState) => ({
    loggedIn: state.session.authToken && state.user.user?.guid,
    isRegisterSuccess: state.session.isRegisterSuccess,
    user: state.user,
    merchantUserGUID: state.merchant.merchants.length > 0 ? state.merchant.merchants[0].userGUID : undefined
  }));

  useEffect(() => {
    dispatch(fetchCollectionByMerchantUserGUID(process.env.REACT_APP_COU_MERCHANT_ID || ''));
  }, []);

  useEffect(() => {
    if (loggedIn && donateConfirmed) {
      navigate('/collection/' + don.current);
    }
  }, [loggedIn, donateConfirmed]);

  const handleClick = (collectionGUID: string) => {
    setDonateConfirmed(true);
    don.current = collectionGUID;
    if (!loggedIn) {
      if (isRegisterSuccess) {
        dispatch(setPopupState('login'));
      } else {
        dispatch(setPopupState('register'));
      }
    }
  };

  const reverseCollection = () => {
    return cloneDeep(merchant.merchantCollection as Post[]).reverse();
  };

  return (
    <div className={cx('card-donation')}>
      <div className={cx('card-content', 'donation-card-content')}>
        <div className={cx('has-text-weight-bold', 'is-size-6', 'mb-4')}>Donation Options</div>
        {/*<div className="pb-4 flex is-justify-content-flex-start">
          <a
            className="button is-yellow donate-with-crypto"
            target="_blank"
            href="https://commerce.coinbase.com/checkout/21a48642-f92e-40f6-b6c8-eed8b86a9e7d">
            DONATE&nbsp;<strong>CRYPTO</strong>
          </a>

          <button
            onClick={() => setShowCreditDonation(!showCreditDonation)}
            className="button is-yellow donate-with-crypto ml-4">
            DONATE&nbsp;<strong>CASH</strong>
          </button>
        </div>*/}
        <div className={cx('is-size-7', 'has-text-muted', 'has-text-left', 'flexbox', 'mb-4')}>
          <div className={cx('mr-2')}>DONATE AND RECEIVE A COMMEMORATIVE NFT</div>
          <div className={cx('hr-bar')} />
        </div>
        {showCreditDonation && <CreditCardDonation onSuccess={() => setShowCreditDonation(false)} />}
        <div className={cx('nfts')}>
          {merchant.merchantCollection ? (
            reverseCollection().map((collection, index) => (
              <div className={cx('nft')} key={collection.guid}>
                <img src={collection.thumbnailImageUrl} />
                <div className={cx('p-4', 'flexbox', 'width-full', '_8mIf0')}>
                  <div className={cx('flex', 'mr-4')}>
                    <div className={cx('has-text-weight-bold')}>{collection.name}</div>
                    {index === 0 ? (
                      <div className={cx('adjust-mobile')}>$10</div>
                    ) : index === 1 ? (
                      <div className={cx('adjust-mobile')}>$100 - $1,000</div>
                    ) : (
                      <div className={cx('adjust-mobile')}>$250,000</div>
                    )}
                    {/* <div>Description</div> */}
                  </div>
                  <div className={cx('flexbox')}>
                    {/*<Stack direction="row" spacing={0} alignItems="center">
                      <div>Cash</div>
                      <Switch />
                      <div>Crypto</div>
                    </Stack>*/}
                    <button
                      onClick={() => handleClick(collection.guid)}
                      className={cx('button', 'is-fullwidth', 'is-yellow')}>
                      DONATE
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <>
              <div className={cx('p-2', 'flexbox', 'width-full')}>
                <Skeleton type="rect" width="100%" height="120px" />
              </div>
              <div className={cx('p-2', 'flexbox', 'width-full')}>
                <Skeleton type="rect" width="100%" height="120px" />
              </div>
              <div className={cx('p-2', 'flexbox', 'width-full')}>
                <Skeleton type="rect" width="100%" height="120px" />
              </div>
            </>
          )}
        </div>

        <div className={cx('is-size-7', 'has-text-muted', 'has-text-left', 'flexbox', 'mt-5', 'mb-4')}>
          <div className={cx('mr-2')}>DONATE CYRPTO</div>
          <div className={cx('hr-bar')} />
        </div>

        <div className={cx('nfts')}>
          <div className={cx('nft')}>
            <div className={cx('flexbox', 'width-full', 'px-4')}>
              <img className={cx('coinbase')} src={coinbase} />
              <div className={cx('has-text-centered', 'mx-auto')}>
                <img className={cx('cryptos')} src={cryptos} />
                <div className={cx('is-size-7')}>
                  Clicking Donate will take you to our partner site, Coinbase to complete your donation.
                </div>
              </div>
              <a
                className={cx('button', 'is-yellow')}
                href="https://commerce.coinbase.com/checkout/21a48642-f92e-40f6-b6c8-eed8b86a9e7d"
                target="_blank">
                DONATE
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTDonations;
