import { httpClient } from 'http/httpClient';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';
import Loading from 'components/Loading';
import DateTime from 'components/DateTime';
import { Post } from 'types';
import { openSnackbar } from 'state/reducers/snackbarReducer';
import { PLATFORM } from 'types';
import { getNftUrl, getSmartContractUrl } from 'utils/post';
import styles from './Collections.module.scss';

const cx = classnames.bind(styles);

const Collections = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Post[]>([]);

  const loadItems = async () => {
    try {
      setLoading(true);
      const response = await httpClient.get(`/posts/search?isPurchasedByCallingUser=true&platform=${PLATFORM}`);
      setData(response.data);
    } catch (e) {
      dispatch(
        openSnackbar({
          message: e + '',
          type: 'danger'
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadItems();
  }, []);

  return (
    <>
      {loading && <Loading />}
      {!loading && data.length === 0 && (
        <div className={cx('no-collections')}>
          <p>You currently have no NFTs.</p>
        </div>
      )}

      {!loading &&
        data.map((item) => (
          <div className={cx('collection')} key={item.guid}>
            <img className={cx('collection-img')} src={item.normalizedContentUrl} alt="profile" />
            <div className={cx('collection-content')}>
              <p>Collection: {item.postGroupName}</p>
              <p>Title: {item.name}</p>
              <p>
                Collaborator:{' '}
                {item.lastSoldMarketplaceListingSellerUserDisplayName || item.marketplaceListingSellerUserDisplayName}
              </p>
              {item?.lastSoldMarketplaceListingDateCreated && (
                <p>
                  Purchased: <DateTime date={item.lastSoldMarketplaceListingDateCreated} />
                </p>
              )}
              {item?.lastSoldMarketplaceListingUSDAmount && <p>Amount: ${item.lastSoldMarketplaceListingUSDAmount}</p>}
              {item?.lastSoldMarketplaceListingETHAmount && (
                <p>Amount: {item.lastSoldMarketplaceListingETHAmount} ETH</p>
              )}
              <p>
                NFT Link:{' '}
                <a href={getNftUrl(item)} target="_blank">
                  {getNftUrl(item)}
                </a>
              </p>
              {
                <a href={getSmartContractUrl(item)} target="_blank">
                  View Smart Contract
                </a>
              }
            </div>
          </div>
        ))}
    </>
  );
};

export default Collections;
