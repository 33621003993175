import React from 'react';
import classnames from 'classnames/bind';

import united24 from 'assets/images/united24.png';
import zelenskyy from 'assets/images/zelenskyy.webp';

import styles from 'routes/Landing/Landing.module.scss';
import { Link } from 'react-router-dom';
import Timer from 'components/Timer';
import countOnUsVideo from 'assets/videos/countonus_final.mp4';

const cx = classnames.bind(styles);

function Landing() {
  return (
    <>
      <div className={cx('yellow')}>
        <div className={cx('container', 'py-6', 'has-text-centered')}>
          <div className={cx('is-size-5')}>LAUNCHING IN</div>
          {/* <div className={cx('is-size-4', 'has-text-weight-bold')}>August 24, 2022, 10am, Kiev</div>
          <div className={cx('is-size-4', 'has-text-weight-bold')}>August 24, 2022, 12am, PST</div> */}
          <div className={cx('flexbox', 'justify-center')}>
            <Timer date={new Date('24 August 2022 00:00 PST')} type={1} />
          </div>

          <div className={cx('is-size-2', 'has-text-weight-bold', 'mt-6')}>
            <div>COUNT ON US FOUNDATION</div>
            <div className={cx('is-size-5')}>A campaign to help rebuild Ukraine and relocate displaced Ukrainians</div>
            <div className={cx('mt-5')}>
              <video controls src={countOnUsVideo} className={cx('mx-auto')} />
            </div>
          </div>
        </div>
      </div>
      <div className={cx('white')}>
        <div className={cx('container', 'py-6', 'has-text-centered')}>
          <div className={cx('is-size-4')}>In partnership with:</div>
          <div>
            <img className={cx('united', 'm-auto')} src={united24} />
            <img className={cx('zelenskyy', 'mt-4')} src={zelenskyy} />
          </div>
        </div>
      </div>

      <div className={cx('yellow')}>
        <div className={cx('container', 'footer', 'py-6', 'has-text-centered')}>
          <div className={cx('is-size-4', 'has-text-weight-bold', 'mt-6')}>
            The Global Initiative of the President of the Ukraine
          </div>
          <Link to="/home">
            <a className={cx('button', 'button-nifty', 'is-black', 'my-4')}>
              Learn more about how you can help build Ukraine
            </a>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Landing;
