import React from 'react';
import classnames from 'classnames/bind';

import header from 'assets/images/nifty-header.jpg';
import avatar1 from 'assets/images/nifty-avatar-1.png';
import avatar2 from 'assets/images/nifty-avatar-2.png';
import avatar3 from 'assets/images/nifty-avatar-3.png';
import avatar4 from 'assets/images/nifty-avatar-4.png';
import avatar5 from 'assets/images/nifty-avatar-5.png';
import military from 'assets/images/military.png';

import styles from './NiftyHome.module.scss';

const cx = classnames.bind(styles);

const NiftyHome = () => {
  return (
    <div>
      <div className={cx('header')}>
        <img src={header} />
        <div className={cx('flags')} />
        <div className={cx('goal')}>
          <div className={cx('amount')}></div>
          {/* <div className={cx('mt-2', 'has-text-weight-bold')} style={{ color: '#215cb5' }}>
            45% of the goal
          </div> */}
        </div>
      </div>
      <div className={cx('main')}>
        <div className={cx('has-text-centered', 'is-size-3')}>DONATE NOW</div>
        <div className={cx('donate')}>
          <div className={cx('mt-1', 'mb-5', 'has-text-centered')}>
            All proceeds go to charities giving aid,
            <br />
            including shelter clothes and food to refugees who need it most.
          </div>
          <div className={cx('columns', 'is-multiline')}>
            <div className={cx('column', 'is-one-third')}>
              <div className={cx('dono')}>
                <img src={avatar1} />
                <div className={cx('amount')}>1 ETH</div>
              </div>
            </div>
            <div className={cx('column', 'is-one-third')}>
              <div className={cx('dono')}>
                <img src={avatar2} />
                <div className={cx('amount')}>1 ETH</div>
              </div>
            </div>
            <div className={cx('column', 'is-one-third')}>
              <div className={cx('dono')}>
                <img src={avatar3} />
                <div className={cx('amount')}>1 ETH</div>
              </div>
            </div>
            <div className={cx('column', 'is-one-third')}>
              <div className={cx('dono')}>
                <img src={avatar4} />
                <div className={cx('amount')}>1 ETH</div>
              </div>
            </div>
            <div className={cx('column', 'is-one-third')}>
              <div className={cx('dono')}>
                <img src={avatar5} />
                <div className={cx('amount')}>1 ETH</div>
              </div>
            </div>
            <div className={cx('column', 'is-one-third')}>
              <div className={cx('field')}>
                <div className={cx('control')}>
                  <input type="password" className={cx('input', 'custom-amount')} placeholder="Other" />
                </div>
              </div>
            </div>
          </div>
          <div className={cx('is-size-7', 'has-text-centered')}>
            ALL CRYPTO DONATIONS RECEIVE A COPY OF 'COUNT ON US'
          </div>
        </div>
        <div className={cx('container', 'my-6')}>
          It is the bleakest humanitarian crisis in Europe this century, with an estimated seven million Ukrainians
          likely to flee the horror. Circle of Hope is raising money to help provide aid, including shelter, food and
          medical support for desperate families who have abandoned their homes with little but what they can carry.
          Donations will help aid organisations that are already on the ground and providing desperatel needed support
        </div>
        <div>
          <img src={military} />
        </div>
      </div>
    </div>
  );
};

export default NiftyHome;
