import React, { FormEvent, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { setPopupState } from 'state/reducers/userReducer';
import { RootState } from 'state/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  loginUser,
  resendPhoneVerification,
  resetRegisterError,
  setUnverifiedAccount,
  verifyByPhoneNumber
} from 'state/reducers/sessionReducer';
import { useNavigate } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import styles from './Verification.module.scss';

const cx = classnames.bind(styles);
const Verification = () => {
  const navigate = useNavigate();

  const { session, user } = useSelector((state: RootState) => ({
    session: state.session,
    user: state.user
  }));

  const dispatch = useDispatch();
  let inputElements: HTMLInputElement[];

  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);

  const onSubmit = (event: FormEvent) => {
    dispatch(resetRegisterError());
    event.preventDefault();

    const code = [...document.getElementsByTagName('input')]
      .filter(({ name }) => name)
      .map(({ value }) => value)
      .join('');

    if (session.guid) {
      dispatch(verifyByPhoneNumber({ guid: session.guid, code }));
    }
  };

  const resend = () => {
    if (session.guid) {
      dispatch(resendPhoneVerification(session.guid));
    } else if (session.guid === undefined || session.guid === null) {
      navigate('/login');
    }
  };

  useEffect(() => {
    if (user.user) {
      navigate('/');
    }
  }, [user]);

  useEffect(() => {
    inputElements = [...document.querySelectorAll('input[name="code"]')] as HTMLInputElement[];
    inputElements.forEach((ele: HTMLElement, index: number) => {
      ele.addEventListener('keydown', (e: KeyboardEvent) => {
        const element = e.target as HTMLInputElement;
        if (e.key === 'Backspace' && element.value === '') inputElements[Math.max(0, index - 1)].focus();
      });
      ele.addEventListener('input', (el) => {
        const element = el.target as HTMLInputElement;
        const [first, ...rest] = element.value;
        element.value = first ?? '';
        const lastInputBox = index === inputElements.length - 1;
        const insertedContent = first !== undefined;
        if (insertedContent && !lastInputBox) {
          inputElements[index + 1].focus();
          inputElements[index + 1].value = rest.join('');
          inputElements[index + 1].dispatchEvent(new Event('input'));
        }
      });
    });
  });

  useEffect(() => {
    if (
      session.errorPayload?.errorCode &&
      session.errorPayload?.errorCode === 'UnverifiedPhoneNumber' &&
      session.errorPayload?.userGUID
    ) {
      localStorage.setItem('lastUrl', '/');
      dispatch(
        setUnverifiedAccount({
          guid: session.errorPayload.userGUID,
          isRegistered: true,
          isVerified: false
        })
      );
      dispatch(resendPhoneVerification(session.errorPayload.userGUID));
      dispatch(resetRegisterError());
    }
  }, [session.errorPayload]);

  useEffect(() => {
    if (session.isVerified) {
      setIsRegisterSuccess(true);
      setTimeout(() => {
        if (session.cacheUser) {
          dispatch(loginUser(cloneDeep(session.cacheUser)));
        }
      }, 3000);
    }
  }, [session.isVerified]);

  return isRegisterSuccess ? (
    <>
      <div className={cx('container')}>
        <div className={cx('has-text-centered')}>
          <div className={cx('has-text-weight-bold', 'is-size-4', 'ft-mt-6')}>
            Thank you
            <br />
            for joining Count On Us Foundation!
          </div>
          <div className={cx('mt-4', 'is-size-6')}>You may now login with your new account.</div>
        </div>
        <div className={cx('has-text-centered', 'mt-6')}>
          <button
            type="button"
            className={cx('button', 'mt-4')}
            onClick={() => {
              if (session.cacheUser) {
                dispatch(loginUser(cloneDeep(session.cacheUser)));
              } else {
                dispatch(setPopupState('login'));
              }
            }}>
            Login
          </button>
        </div>
      </div>
    </>
  ) : (
    <div className={cx('verify')}>
      <form onSubmit={onSubmit}>
        <div className={cx('mb-4')}>
          Before you can login with your new account, you need to verify your phone number
          <br />
          for security and account recovery.
        </div>
        Enter the verification that was sent to your mobile phone.
        <div className={cx('flexbox', 'control')}>
          <input className={cx('code', 'input')} type="number" name="code" required />
          <input className={cx('code', 'input')} type="number" name="code" required />
          <input className={cx('code', 'input')} type="number" name="code" required />
          <input className={cx('code', 'input')} type="number" name="code" required />
          <input className={cx('code', 'input')} type="number" name="code" required />
          <input className={cx('code', 'input')} type="number" name="code" required />
        </div>
        {(session.loginError || session.registerError) && (
          <div className={cx('ft-mt-4', 'has-text-danger')}>{session.loginError || session.registerError}</div>
        )}
        <div className={cx('mb-4')}>
          <button type="submit" className={cx('button', 'is-full-width', 'mt-4')}>
            Proceed
          </button>
        </div>
        <div className={cx('is-link', 'mt-2')} onClick={() => resend()}>
          Resend Verification Code
        </div>
      </form>
    </div>
  );
};

export default Verification;
