import React from 'react';
import classnames from 'classnames/bind';

import { Post } from 'types';

import defaultAvatar from 'assets/images/default-avatar.png';

import styles from './CollectionCard.module.scss';

const cx = classnames.bind(styles);

interface CollectionCardProps {
  collection?: Post;
  collectionBanner?: string;
  pieces: number;
  sales?: number;
  views?: string;
  type: number;
}

const CollectionCard = (props: CollectionCardProps) => {
  return (
    <div className={cx('collection-card')}>
      <div className={cx('background')} />
      <div className={cx('preview')}>
        <img className={cx('main')} src={props.collectionBanner} />
      </div>
      <div className={cx('details')}>
        {props.type === 1 && (
          <>
            <div className={cx('flexbox', 'flex-column')}>
              <div className={cx('name')}>{props.collection?.name}</div>
              <div className={cx('flexbox')}>
                <object className={cx('avatar')} data={props.collection?.profileImageUrl || defaultAvatar} />
                <div className={cx('has-text-weight-semibold', 'ml-2')}>
                  {props.collection?.merchantName || props.collection?.userUsername}
                </div>
              </div>
            </div>
            <div className={cx('description', 'mt-2')}>{props.collection?.description}</div>
          </>
        )}
        {props.type === 2 && (
          <>
            <div className={cx('name')}>{props.collection?.name}</div>
            <div className={cx('flexbox', 'mt-2')}>
              <div className={cx('mr-auto', 'has-text-left')}>
                <div className={cx('is-size-7')}>Items</div>
                <div className={cx('has-text-weight-bold', 'is-size-6')}>{props.pieces}</div>
              </div>
              {/* <div className={cx('has-text-centered')}>
                <div className={cx('has-text-weight-bold', 'is-size-6')}>{props.sales} ETH</div>
                <div className={cx('is-size-7')}>Total Bids</div>
              </div> */}
              <div className={cx('ml-auto', 'has-text-right')}>
                <div className={cx('is-size-7')}>Views</div>
                <div className={cx('has-text-weight-bold', 'is-size-6')}>{props.views}</div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CollectionCard;
