import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classnames from 'classnames/bind';
import {
  createView,
  fetchCollectionById,
  fetchCollectionItem,
  resetCollection,
  resetItem
} from 'state/reducers/postReducer';
import { RootState } from 'state/store';

import Skeleton from 'components/Skeleton';
import PostItem from 'components/PostItem';
import { Post } from 'types';

import moment from 'moment';
import styles from './Collection.module.scss';

const cx = classnames.bind(styles);

const Collection = () => {
  const { post } = useSelector((state: RootState) => ({
    post: state.post
  }));
  const { guid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const viewPiece = (post: Post) => {
    dispatch(resetItem());
    navigate(`/piece/${guid}/${post.guid}`);
  };

  const incrementViews = (post?: Post) => {
    const view = {
      merchantGUID: post?.merchantGUID,
      postGUID: post?.guid,
      dateViewed: moment().format('MM/DD/YY hh:mm:ss')
    };

    dispatch(createView(view));
  };

  useEffect(() => {
    if (guid && post.collection?.guid !== guid) {
      dispatch(fetchCollectionById(guid));
    }
  }, [guid]);

  useEffect(() => {
    if (guid && !post.collection?.items) {
      dispatch(fetchCollectionItem(guid));
    }
  }, [post.collection?.guid]);

  useEffect(() => {
    if (post.collection) {
      incrementViews(post.collection);
    }

    return () => {
      dispatch(resetCollection());
    };
  }, []);

  return (
    <div className={cx('malibu')}>
      <div className="container">
        <div className={cx('py-6')}>
          <div className={cx('is-size-2', 'has-text-weight-bold')}>{post.collection?.name}</div>
          <div className={cx('is-size-6')}>{post.collection?.description}</div>
          {/*{post.collection?.guid === '4489a410-98e1-44ca-89e9-0085ae0372c8' && (
            <div className={cx('is-size-6')}>
              <p>
                There are 33 countries that have committed resources to aid Ukraine in their fight for freedom. The
                flags for each of these countries are represented below. To make a donation, select a flag and donate in
                either cash or crypto. You will receive a commemorative NFT of your flag that you can proudly display
                and share with others.
              </p>
              <ul className={cx('default-list', 'mx-6')}>
                <li>31 NATO Flags Available for selection</li>
                <li>Each flag valued at $10</li>
                <li>Persons can select multiple flags for checkout</li>
              </ul>
            </div>
          )}
          {post.collection?.guid === '18b5d9a2-ae41-4ab4-8156-ca0c8bf968d8' && (
            <div className={cx('is-size-6')}>
              <p>
                Each of the images below have been taken from the Count On Us music video. All artwork is original and
                designed specifically for the Count On Us Foundation.
              </p>
            </div>
          )}
          {post.collection?.guid === '34ceb513-8acf-4faf-b442-51fd27268ce1' && (
            <div className={cx('is-size-6')}>
              <p>
                Thank you for your interest in being one of our mega donors. Your support is valued and significantly
                helps the Count On Us Fodunation delvier on our promise to help rebuild Ukraine.
              </p>
              <p>
                <i>
                  Please select here if you are interested in purchasing the Metaverse Experience. You will be contacted
                  directly from our team to set up your transfer. You will be sent instructions on Metaverse access and
                  coordinates.
                </i>
              </p>
            </div>
          )}*/}
          {/* <p className={cx('is-size-6')}>
            The Count On Us Foundation was designed to help Ukraine and help humanity. We embarked on this mission to
            build a collection to stop oppression, regain trust, and spark the mind of our fellow humans. We hope that
            you want to help build this community as much as we do. Each Digital Asset purchase is one step closer to
            restoring faith in humanity and our Ukrainian people.
          </p> */}
        </div>
        <div className={cx('ocean')}>
          <div className="trance-wrapper">
            <div className="trance">
              {post.collection?.items ? (
                <>
                  {post.collection?.items.length > 0 ? (
                    post.collection.items.map(
                      (post: Post) =>
                        (post.marketplaceListingETHAmount || post.marketplaceListingUSDAmount) && (
                          <PostItem post={post} viewPiece={viewPiece} key={post.guid} />
                        )
                    )
                  ) : (
                    <div
                      className={cx(
                        'is-size-4',
                        'has-text-weight-bold',
                        'width-full',
                        'has-text-centered',
                        'has-text-muted',
                        'py-6'
                      )}>
                      All items in this collection are sold out.
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className={cx('only-desktop')}>
                    <Skeleton
                      className={cx('collection-skeleton')}
                      type="rect"
                      height="422px"
                      width="calc(25% - 1rem)"
                    />
                    <Skeleton
                      className={cx('collection-skeleton')}
                      type="rect"
                      height="422px"
                      width="calc(25% - 1rem)"
                    />
                    <Skeleton
                      className={cx('collection-skeleton')}
                      type="rect"
                      height="422px"
                      width="calc(25% - 1rem)"
                    />
                    <Skeleton
                      className={cx('collection-skeleton')}
                      type="rect"
                      height="422px"
                      width="calc(25% - 1rem)"
                    />
                  </div>
                  <div className={cx('only-mobile')}>
                    <Skeleton
                      className={cx('collection-skeleton')}
                      type="rect"
                      height="332px"
                      width="calc(50% - 1rem)"
                    />
                    <Skeleton
                      className={cx('collection-skeleton')}
                      type="rect"
                      height="332px"
                      width="calc(50% - 1rem)"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collection;
