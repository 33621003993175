import React from 'react';
import classnames from 'classnames/bind';

import styles from './PrivacyPolicy.module.scss';

const cx = classnames.bind(styles);

const PrivacyPolicy = () => {
  return (
    <div className={cx('container', 'ocean', 'pt-6')}>
      <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-3')}>Privacy Policy</div>
      <div className={cx('mt-4')}>
        <div className={cx('has-text-weight-bold has-text-primary is-size-5 mt-5')}>
          Last Updated: August 22, 2022 CountOnUs Foundation (“CountOnUs,” “we” or “us”) is committed to protecting your
          privacy. We have prepared this Privacy Policy to describe to you our practices regarding the Personal
          Information (as defined below) we collect, why we collect it, and how we use and disclose it.
        </div>
        <div className={cx('mt-4')}>
          Your privacy matters to us, so please do take the time to get to know and familiarize yourself with our
          policies and practices. Please understand that we reserve the right to change any of our policies and
          practices at any time, but you can always find the latest version of this Privacy Policy here on this page.
        </div>
      </div>
      <div className={cx('mt-4')}>
        <ol className={cx('terms-list')}>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4')}>
              Personal Information We Collect
            </div>
            <div className={cx('mt-4')}>
              As used herein, “Personal Information” means information that identifies or is reasonably capable of
              identifying an individual, directly or indirectly, and information that is capable of being associated
              with an identified or reasonably identifiable individual.
            </div>
            <div className={cx('mt-4')}>
              <ol className={cx('primary-sub-list')}>
                <li className={cx('sub-list-item')}>
                  <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-6')}>
                    Personal Information we collect from you
                  </div>
                  <div className={cx('mt-4')}>
                    We may collect the following categories of Personal Information directly from you:
                  </div>
                  <div className={cx('mt-4')}>
                    <ul className={cx('item-list')}>
                      <li>
                        Identification Information such as <strong>name, email, phone number, postal address;</strong>
                      </li>
                      <li>
                        Commercial Activity <strong>such as donation activity and account balances;</strong>
                      </li>
                      <li>
                        Correspondence{' '}
                        <strong>
                          such as information you provide to us in correspondence, including account opening and
                          customer support; and
                        </strong>
                      </li>
                      <li>
                        Sensory Information <strong>such as images that you upload to your User Profile;</strong>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className={cx('sub-list-item')}>
                  <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-6')}>
                    Personal Information we collect automatically
                  </div>
                  <div className={cx('mt-4')}>
                    We may collect the following categories of Personal Information automatically through your use of
                    our services:
                  </div>
                  <div className={cx('mt-4')}>
                    <ul className={cx('item-list')}>
                      <li>
                        Online Identifiers <strong>such as IP address, domain name;</strong>
                      </li>
                      <li>
                        Device Information <strong>such as hardware, operating system, browser;</strong>
                      </li>
                      <li>
                        Usage Data{' '}
                        <strong>
                          such as system activity, internal and external information related to CountOnUs pages that you
                          visit, clickstream information; and
                        </strong>
                      </li>
                    </ul>
                  </div>
                  <div className={cx('mt-4')}>
                    Our automatic collection of Personal Information may involve the use of Cookies, described in
                    greater detail below.
                  </div>
                </li>

                <li className={cx('sub-list-item')}>
                  <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-6')}>
                    Personal Information we collect from third parties
                  </div>
                  <div className={cx('mt-4')}>
                    We may collect and/or verify the following categories of Personal Information about you from third
                    parties, including service providers and our affiliates:
                  </div>
                  <div className={cx('mt-4')}>
                    <ul className={cx('item-list')}>
                      <li>
                        Identification Information <strong>such as name, email, phone number, postal address</strong>
                      </li>
                      <li>
                        Transaction Information{' '}
                        <strong>
                          such as public blockchain data (bitcoin, ether, and other Digital Assets are not truly
                          anonymous. We, and any others who can match your public Digital Asset address to other
                          Personal Information about you, may be able to identify you from a blockchain transaction
                          because, in some circumstances, Personal Information published on a blockchain (such as your
                          Digital Asset address and IP address) can be correlated with Personal Information that we and
                          others may have. Furthermore, by using data analysis techniques on a given blockchain, it may
                          be possible to identify other Personal Information about you);
                        </strong>
                      </li>
                      <li>
                        Financial Information{' '}
                        <strong>
                          such as bank account information, routing number, credit card number, debit card number; and
                        </strong>
                      </li>
                      <li>
                        Additional Information{' '}
                        <strong>
                          at our discretion to comply with legal obligations. 1.4. 4. Accuracy and retention of Personal
                          Information
                        </strong>
                      </li>
                    </ul>
                  </div>
                  <div className={cx('mt-4')}>
                    We take reasonable and practicable steps to ensure that your Personal Information held by us is (i)
                    accurate with regard to the purposes for which it is to be used, and (ii) not kept longer than is
                    necessary for the fulfillment of the purpose for which it is to be used.
                  </div>
                </li>
              </ol>
            </div>
          </li>

          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4', 'mt-4')}>
              How We Use Your Personal Information
            </div>
            <div className={cx('mt-4')}>
              We collect Personal Information about you in an attempt to provide you with the best experience possible,
              protect you from risks related to improper use and fraud, and help us maintain and improve our Services.
              We may use your Personal Information to:
            </div>
            <div className={cx('mt-4')}>
              <ol className={cx('ordered-list')}>
                <li>
                  Provide you with our Services.{' '}
                  <strong>
                    We use your Personal Information to provide you with our Services pursuant to the terms of our Terms
                    of Use. For example, in order to facilitate fiat transfers out of your account, we need to know your
                    financial information.
                  </strong>
                </li>

                <li>
                  Comply with legal and regulatory requirements.{' '}
                  <strong>We process your Personal Information as required by applicable laws and regulations.</strong>
                </li>

                <li>
                  Detect and prevent fraud.{' '}
                  <strong>
                    We process your Personal Information to detect and prevent fraud on your account, which is
                    especially important given the irreversible nature of cryptocurrency transactions.
                  </strong>
                </li>

                <li>
                  Protect the security and integrity of our Services.{' '}
                  <strong>
                    We use your Personal Information, including information about your device and your activity on
                    CountOnUs to maintain the security of your account and the CountOnUs platform.
                  </strong>
                </li>

                <li>
                  Provide you with customer support.{' '}
                  <strong>
                    We process your Personal Information when you contact our support team with questions about or
                    issues with your account.
                  </strong>
                </li>
                <li>
                  Market our products.{' '}
                  <strong>
                    We may contact you with information about our Services. We will only do so with your permission,
                    which can be revoked at any time.
                  </strong>
                </li>
              </ol>
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4', 'mt-4')}>
              How We Share Your Personal Information
            </div>
            <div className={cx('mt-4')}>
              We will not share your Personal Information with third parties, except as described below:
            </div>
            <div className={cx('mt-4')}>
              <ol className={cx('ordered-list')}>
                <li>
                  Service Providers.{' '}
                  <strong>
                    We may share your Personal Information with third-party service providers for business or commercial
                    purposes, including fraud detection and prevention, security threat detection, payment processing,
                    customer support, data analytics, Information Technology, advertising and marketing, network
                    infrastructure, storage, transaction monitoring. We share your Personal Information with these
                    service providers only so that they can provide us with the services, and we prohibit our service
                    providers from using or disclosing your Personal Information for any other purpose.
                  </strong>
                </li>

                <li>
                  Affiliates.{' '}
                  <strong>
                    We may share Personal Information about you with our affiliates in the ordinary course of business
                    and offering our Services to you.
                  </strong>
                </li>

                <li>
                  Law Enforcement.{' '}
                  <strong>
                    We may be compelled to share your Personal Information with law enforcement, government officials,
                    and regulators.
                  </strong>
                </li>

                <li>
                  Corporate Transactions.{' '}
                  <strong>
                    We may disclose Personal Information in the event of a proposed or consummated merger, acquisition,
                    reorganization, asset sale, or similar corporate transaction, or in the event of a bankruptcy or
                    dissolution.
                  </strong>
                </li>

                <li>
                  Professional Advisors.{' '}
                  <strong>
                    We may share your Personal Information with our professional advisors, including legal, accounting,
                    or other consulting services for purposes of audits or to comply with our legal obligations.
                  </strong>
                </li>
                <li>
                  Consent. <strong>We may share your Personal Information with your consent.</strong>
                </li>
              </ol>
            </div>
            <div className={cx('mt-4')}>
              If we decide to modify the purpose for which your Personal Information is collected and used, we will
              amend this Privacy Policy.
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4', 'mt-4')}>Cookies</div>
            <div className={cx('mt-4')}>
              <p>
                When you access CountOnUs, we may make use of the standard practice of placing tiny data files called
                cookies, flash cookies, pixel tags, or other tracking tools (herein, “Cookies”) on your computer or
                other devices used to visit CountOnUs. We use Cookies to help us recognize you as a customer, collect
                information about your use of CountOnUs to better customize our services and content for you, and
                collect information about your computer or other access devices to: (i) ensure that your account
                security has not been compromised by detecting irregular, suspicious, or potentially fraudulent account
                activities; (ii) assess and improve our services and advertising campaigns.
              </p>
              <p>
                You also can learn more about cookies by visiting http://www.allaboutcookies.org, which includes
                additional useful information on cookies and how to block cookies on different types of browsers and
                mobile devices. Please note that if you reject cookies, you will not be able to use some or all of
                CountOnUs. If you do not consent to the placing of Cookies on your device, please do not visit, access,
                or use CountOnUs.
              </p>
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4', 'mt-4')}>Direct Marketing</div>
            <div className={cx('mt-4')}>
              Subject to applicable laws and regulations, we may from time to time send direct marketing materials
              promoting services, products, facilities, or activities to you using information collected from or about
              you. You may also opt-out of such communications by following the directions provided in any marketing
              communication. It is our policy to not provide your Personal Information for those third parties’ direct
              marketing purposes without your consent.
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4', 'mt-4')}>
              Information Security
            </div>
            <div className={cx('mt-4')}>
              No security is foolproof, and the Internet is an insecure medium. We cannot guarantee absolute security,
              but we work hard to protect CountOnUs and you from unauthorized access to or unauthorized alteration,
              disclosure, or destruction of Personal Information we collect and store. Measures we take include
              encryption of the CountOnUs website communications with SSL; optional two-factor authentication; periodic
              review of our Personal Information collection, storage, and processing practices; and restricted access to
              your Personal Information on a need-to-know basis for our employees, contractors and agents who are
              subject to strict contractual confidentiality obligations and may be disciplined or terminated if they
              fail to meet these obligations.
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4')}>
              Information For Persons Subject to EU Data Protection Law
            </div>
            <div className={cx('mt-4')}>
              <p>
                While customers who are located in the European Union (“EU”), European Economic Area (“EEA”) or the
                Channel Islands, or other locations subject to EU data protection law (collectively, “Europe”) are
                customers of our US entity, we recognize and, to the extent applicable to us, adhere to relevant EU data
                protection laws. For purposes of this section, “personal data” has the meaning provided in the General
                Data Protection Regulation (EU) 2016/679 (“GDPR”).
              </p>
              <p>Lawful bases for processing</p>
              <p>We process personal data subject to GDPR on one or more of the following legal bases:</p>
            </div>
            <div className={cx('mt-4')}>
              <ul className={cx('unordered-list')}>
                <li>
                  Legal Obligation:{' '}
                  <strong>to conduct anti-fraud and to fulfill our retention and other legal obligations;</strong>
                </li>
                <li>
                  Contractual Obligation:{' '}
                  <strong>
                    to satisfy our obligations to you under our Terms of Use, including to provide you with our Services
                    and customer support services, and to optimize and enhance CountOnUs;
                  </strong>
                </li>
                <li>
                  Legitimate Interest:{' '}
                  <strong>
                    to monitor the usage of CountOnUs, conduct automated and manual security checks of our Services, to
                    protect our rights; and
                  </strong>
                </li>
                <li>
                  Consent:{' '}
                  <strong>
                    to market CountOnUs and our Services. You may withdraw your consent at any time without affecting
                    the lawfulness of processing based on consent before consent is withdrawn.
                  </strong>
                </li>
              </ul>
            </div>

            <div className={cx('mt-4')}>
              <p>European privacy rights</p>
              <p>
                European residents have the following rights under GDPR, subject to certain exceptions provided under
                the law, with respect to their personal data:
              </p>
            </div>

            <div className={cx('mt-4')}>
              <ul className={cx('unordered-list')}>
                <li>
                  Rights to Access and Rectification.{' '}
                  <strong>
                    You may submit a request that CountOnUs disclose the personal data that we process about you and
                    correct any inaccurate personal data.
                  </strong>
                </li>
                <li>
                  Right to Erasure.{' '}
                  <strong>
                    You may submit a request that CountOnUs delete the personal data that we have about you.
                  </strong>
                </li>
                <li>
                  Right to Restriction of Processing.{' '}
                  <strong>
                    You have the right to restrict or object to our processing of your personal data under certain
                    circumstances.
                  </strong>
                </li>
                <li>
                  Right to Data Portability.{' '}
                  <strong>
                    You have the right to receive the personal data you have provided to us in an electronic format and
                    to transmit that personal data to another data controller.
                  </strong>
                </li>
              </ul>
            </div>

            <div className={cx('mt-4')}>
              <p>
                To submit a request to exercise these rights, please contact us using the methods described at the end
                of this Privacy Policy. When handling requests to exercise European privacy rights, we check the
                identity of the requesting party to ensure that he or she is the person legally entitled to make such
                request. While we maintain a policy to respond to these requests free of charge, should your request be
                repetitive or unduly onerous, we reserve the right to charge you a reasonable fee for compliance with
                your request.
              </p>

              <p>Collection and transfer of data outside the EEA</p>
              <p>
                CountOnUs operates with many of our systems based in the United States. As a result, we may transfer
                personal data from Europe to third countries outside of Europe, including the United States, under the
                following conditions:
              </p>
            </div>

            <div className={cx('mt-4')}>
              <ul className={cx('unordered-list')}>
                <li>
                  Contractual Obligation.{' '}
                  <strong>
                    Where transfers are necessary to satisfy our obligation to you under our Terms of Use, including to
                    provide you with our Services and customer support services, and to optimize and enhance CountOnUs;
                    and
                  </strong>
                </li>
                <li>
                  Consent:{' '}
                  <strong>where you have consented to the transfer of your personal data to a third country.</strong>
                </li>
              </ul>
            </div>

            <div className={cx('mt-4')}>
              <p>
                Where transfers to a third country are based on your consent, you may withdraw your consent at any time.
                Please understand, however, that our services may not be available if we are unable to transfer personal
                data to third countries.
              </p>
              <p>
                When we transfer personal data to third countries, we endeavor to ensure adequate safeguards are
                implemented, for example through the use of standard contractual clauses or Privacy Shield
                certification.
              </p>
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4', 'mt-4')}>Contact Us</div>
            <div className={cx('mt-4', 'mb-4')}>
              If you have questions or concerns regarding this policy or our processing of your Personal Information,
              please feel free to email us at <a href="support@CountOnUs.com">support@CountOnUs.com</a>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
