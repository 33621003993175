import React, { useState } from 'react';
import { ethers, Transaction } from 'ethers';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import classnames from 'classnames/bind';

import Checkbox from 'components/Checkbox';
import CreditCardList from 'components/CreditCardList';
import CreditCardForm from 'components/CreditCardForm';
import Modal from 'components/Modal';
import Wallet from 'routes/Account/Wallet';
import { bidItem, purchaseItem } from 'state/reducers/postReducer';
import { openSnackbar } from 'state/reducers/snackbarReducer';
import { addCryptoWallet, setConnectingValue } from 'state/reducers/userReducer';
import { CreditCard, PaymentProfile } from 'types';
import { RootState } from 'state/store';
import { selectDefaultPaymentCard } from 'state/selectors/user';
import { getMetaMaskInfo } from 'utils/crypto';
import ethereum from 'assets/images/ethereum-brands.svg';

import { CreditCard as CreditCardIcon } from '@mui/icons-material';

import PurchaseInfo from './PurchaseInfo';

import styles from './PurchaseButton.module.scss';

const cx = classnames.bind(styles);

export interface PurchaseButtonProps {
  currency?: string;
}

const PurchaseButton = ({ currency }: PurchaseButtonProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultPaymentCard = useSelector(selectDefaultPaymentCard);
  const { post, user } = useSelector((state: RootState) => ({
    post: state.post,
    merchant: state.merchant,
    user: state.user
  }));
  const { connecting } = useSelector((state: RootState) => state.user);

  const [showCardModal, setShowCardModal] = useState(false);
  const [isShowBidModal, setIsShowBidModal] = useState(false);
  const [isShowInformationModal, setIsShowInformationModal] = useState(false);

  const [selectedCard, setSelectedCard] = useState<PaymentProfile | null>(null);
  const [showCryptoModal, setCryptoModal] = useState(false);
  const [isAcceptCardTerms, setIsAcceptCardTerms] = useState(false);
  const [isSaveCard, setIsSaveCard] = useState(false);
  const [acceptWalletTS, setAcceptWalletTS] = useState(false);
  // const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [isNewCard, setIsNewCard] = useState(false);
  const [creditCardData, setCreditCardData] = useState<CreditCard | undefined>(undefined);
  const [bidAmount, setBidAmount] = useState(0);

  const handleClick = async () => {
    dispatch(setConnectingValue(false));
    if (post.item?.marketplaceListingSellerUserGUID === user.user?.guid) {
      dispatch(
        openSnackbar({
          message: 'Unable to continue. This is listed by yourself.',
          type: 'danger'
        })
      );
      return;
    }
    if (post.item?.marketplaceListingPriceType === 'BestOffer') {
      setIsShowBidModal(true);
    } else {
      if (currency === 'USD') {
        setShowCardModal(true);

        if (!(user.paymentProfiles && user.paymentProfiles.length > 0)) {
          setIsNewCard(true);
        }
      } else {
        try {
          const { wallet } = await getMetaMaskInfo();
          const registered = user.cryptoWallets.find((userWallet) => userWallet.publicAddress === wallet.publicAddress);
          if (registered?.guid) {
            setCryptoModal(true);
          } else {
            dispatch(setConnectingValue(true));
            dispatch(
              addCryptoWallet({
                wallet,
                raiseError: true,
                onSuccess: () => {
                  setCryptoModal(true);
                  dispatch(setConnectingValue(false));
                }
              })
            );
          }
        } catch (e) {
          dispatch(
            openSnackbar({
              message: 'Unable to continue. Please check that your wallet is connected and has sufficient balance.',
              type: 'danger'
            })
          );
          dispatch(setConnectingValue(false));
        }
      }
    }
  };

  const handleBid = () => {
    const marketplaceListingGUID = post.item?.marketplaceListingGUID || '';
    if (bidAmount > (post.item?.marketplaceListingUSDAmount || 0)) {
      dispatch(
        bidItem({
          marketplaceListingGUID,
          navigate,
          payload: {
            usdAmount: bidAmount,
            counterUSDAmount: bidAmount
          }
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: 'Your bid must be higher than the current bid price.',
          type: 'danger'
        })
      );
    }
  };

  const handleCardPurchase = () => {
    const card = defaultPaymentCard || selectedCard;
    const marketplaceListingGUID = post.item?.marketplaceListingGUID || '';
    if (isNewCard) {
      const temp = cloneDeep(creditCardData) as CreditCard;

      temp.creditCardNumber = temp.creditCardNumber.replace(/\s/g, '');
      temp.expirationDate = temp.expirationDate.replace(/\s/g, '');

      dispatch(
        purchaseItem({
          marketplaceListingGUID,
          navigate,
          payload: {
            type: 'Token',
            creditCard: temp,
            saveCreditCard: isSaveCard
          }
        })
      );
    } else {
      dispatch(
        purchaseItem({
          marketplaceListingGUID,
          navigate,
          payload: {
            type: 'Token',
            paymentProfileGUID: card?.guid
          }
        })
      );
    }
  };

  const handleCryptoPay = async () => {
    try {
      const ether = post.item?.marketplaceListingETHAmount;
      const walletAddress = post.item?.marketplaceListingSellerUserDefaultWalletPublicAddress;
      // eslint-disable-next-line no-console
      console.log(ether, walletAddress);
      if (ether && walletAddress) {
        const { signer } = await getMetaMaskInfo();
        ethers.utils.getAddress(walletAddress);
        const tx: Transaction = await signer.sendTransaction({
          to: walletAddress,
          value: ethers.utils.parseEther(`${ether}`)
        });

        const marketplaceListingGUID = post.item?.marketplaceListingGUID || '';
        dispatch(
          purchaseItem({
            marketplaceListingGUID,
            navigate,
            payload: {
              type: 'Token',
              blockchainTransactionId: tx.hash
            }
          })
        );
      } else {
        dispatch(
          openSnackbar({
            message: "Unable to continue. Merchant doesn't register wallet yet.",
            type: 'danger'
          })
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      // @TODO - fix hacky solution
      const errMsg = ((err?.message || err) + '').split('[')[0].trim();
      console.error(errMsg);

      dispatch(
        openSnackbar({
          message: 'Unable to continue. Please check that your wallet is connected and has sufficient balance.',
          type: 'danger'
        })
      );
    }
  };

  const fixPretty = () => {
    return currency === 'USD' ? (
      <div className={cx('flexbox')}>
        <CreditCardIcon className={cx('mr-2')} />
        DONATE CASH
      </div>
    ) : (
      <div className={cx('flexbox')}>
        <img src={ethereum} className={cx('ethereum', 'mr-2')} />
        DONATE CRYPTO
      </div>
    );
  };

  return (
    <>
      <div className={cx('mb-2rem')}>
        <button
          className={`button nox ${connecting ? 'is-loading' : ''}`}
          onClick={() =>
            post?.item?.marketplaceListingUSDAmount &&
            post?.item?.marketplaceListingUSDAmount >= 250000 &&
            currency === 'USD'
              ? setIsShowInformationModal(true)
              : handleClick()
          }
          disabled={connecting}>
          {post?.item?.marketplaceListingPriceType === 'BestOffer' ? 'Place Bid' : fixPretty()}
        </button>
      </div>
      {showCardModal && (
        <Modal onClose={!connecting ? () => setShowCardModal(false) : undefined}>
          {!connecting ? (
            <>
              <PurchaseInfo item={post.item} currency={currency} />
              {!isNewCard && user.paymentProfiles && user.paymentProfiles.length > 0 ? (
                <div className={cx('has-text-left')}>
                  <div className="is-size-4 has-text-weight-bold mb-4">Card Information</div>
                  <div className={cx('card-grid')}>
                    {user.paymentProfiles.map((card: PaymentProfile) => (
                      <div key={card.guid} className={cx('card-cell')}>
                        <CreditCardList
                          isSelected={selectedCard?.guid === card.guid}
                          card={card}
                          size="sm"
                          onSelect={(c) => setSelectedCard(c)}
                        />
                      </div>
                    ))}
                    {/*        {showAddCardModal && (
                  <Modal onClose={() => setShowAddCardModal(false)}>
                    <CreditCardModal onClose={() => setShowAddCardModal(false)} />
                  </Modal>
                )}*/}
                  </div>
                  <div className={cx('mt-2')}>
                    <Checkbox
                      label="I agree that all donations are final and have read the Count On Us Foundation"
                      name="accept"
                      onChange={(e) => setIsAcceptCardTerms(e)}
                      terms={true}
                    />
                  </div>
                  <div className="mt-2">
                    <button
                      disabled={(!defaultPaymentCard && !selectedCard) || !isAcceptCardTerms}
                      className="button"
                      onClick={handleCardPurchase}>
                      Confirm purchase
                    </button>
                    <button className="button is-clear ml-4" onClick={() => setIsNewCard(true)}>
                      Add New Card
                    </button>
                  </div>
                </div>
              ) : (
                <div className={cx('has-text-left')}>
                  <CreditCardForm data={setCreditCardData} />
                  <div className={cx('mt-2')}>
                    <Checkbox
                      label="I agree that all donations are final and have read the Count On Us Foundation"
                      name="accept"
                      onChange={(e) => setIsAcceptCardTerms(e)}
                      terms={true}
                    />
                    <Checkbox label="Save card for future purchases." name="save" onChange={(e) => setIsSaveCard(e)} />
                  </div>
                  <div className={cx('mt-2')}>
                    <button
                      type="submit"
                      className={cx('button')}
                      disabled={!isAcceptCardTerms}
                      onClick={handleCardPurchase}>
                      Confirm purchase
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className={cx('purchase-spinner')}>
              <div className={cx('py-5')}>
                <div className="lds-spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className={cx('is-size-5', 'has-text-weight-bold', 'mt-2')}>Processing donation</div>
              <div>Please wait while we transfer this commemorative NFT to your wallet...</div>
            </div>
          )}
        </Modal>
      )}

      {showCryptoModal && (
        <Modal onClose={!connecting ? () => setCryptoModal(false) : undefined} size="md">
          {!connecting ? (
            <>
              <PurchaseInfo item={post.item} currency={currency} />
              <Wallet profile={false} showConnect={user.cryptoWallets.length === 0} />
              {user.cryptoWallets.length > 0 && (
                <>
                  <div className="mt-2">
                    <label className="checkbox is-size-7">
                      <input
                        type="checkbox"
                        checked={acceptWalletTS}
                        onChange={(e) => setAcceptWalletTS(e.target.checked)}
                      />{' '}
                      I agree that all donations are final and have read the Count On Us Foundation
                      <span className={cx('is-link')}>
                        <Link target={'_blank'} to="/terms-of-use">
                          Terms and Conditions
                        </Link>
                      </span>
                    </label>
                  </div>
                  <div className="mt-2">
                    <button
                      disabled={connecting || !acceptWalletTS}
                      className={`button ${connecting ? 'is-loading' : ''}`}
                      onClick={handleCryptoPay}>
                      Confirm purchase
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className={cx('purchase-spinner')}>
              <div className={cx('py-5')}>
                <div className="lds-spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className={cx('is-size-5', 'has-text-weight-bold', 'mt-2')}>Processing donation</div>
              <div>Please wait while we transfer this commemorative NFT to your wallet...</div>
            </div>
          )}
        </Modal>
      )}

      {isShowBidModal && (
        <Modal onClose={() => setIsShowBidModal(false)}>
          <PurchaseInfo item={post.item} currency={currency} />
          <div className={cx('flexbox')}>
            <div className={cx('mb-4', 'mr-4')}>Enter your bid:</div>
            <div className={cx('field')}>
              <div className={cx('control')}>
                <input
                  type="number"
                  className={cx('input')}
                  defaultValue={post.item?.marketplaceListingUSDAmount}
                  onChange={(e) => setBidAmount(parseInt(e.target.value))}
                />
              </div>
              <div className={cx('is-size-7')}>Bid must be higher than current bid price.</div>
            </div>
          </div>
          <div className={cx('flexbox', 'mt-4')}>
            <button type="submit" className={cx('button')} onClick={handleBid}>
              Confirm bid
            </button>
          </div>
        </Modal>
      )}

      {isShowInformationModal && (
        <Modal onClose={() => setIsShowInformationModal(false)}>
          <div className={cx('has-text-left')}>
            <div className="is-size-4 has-text-weight-bold mb-4">Card Information</div>
            <p>
              Thank you for your interest to donate to the Count On Us Foundation. All large cash donations need to be
              done via wire of ACH. Please contact Jill Van Balen below for more information on how we can help me make
              this significant donation.
              <br />
              Thank you.
            </p>
            <p className="has-text-centered is-size-4">jill@countonusfoundation.org</p>
          </div>
          <div className={cx('flexbox', 'mt-4')}>
            <button type="button" className={cx('button')} onClick={() => setIsShowInformationModal(false)}>
              Close
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PurchaseButton;
