import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FulfilledAction, PendingAction, RejectedAction, RootState } from 'state/store';
import { User, PaymentProfile, CreditCard, CryptoWallet, PasswordChangeRequest } from 'types';
import { GENERAL_ERR_MSG } from 'utils/copies';

export const updateUserProfile = createAsyncThunk('user/updateUserProfile', async (user: User, { dispatch }) => {
  await httpClient.patch(`/users/${user.guid}`, user);
  dispatch(getCurrentUser());
});

export const getCurrentUser = createAsyncThunk('user/getCurrentUser', async () => {
  const response = await httpClient.get('/users/current');
  return response.data;
});

export const getPaymentProfiles = createAsyncThunk('user/getPaymentProfiles', async () => {
  const response = await httpClient.get('/users/current/userpaymentprofiles');
  return response.data;
});

export const addPaymentProfile = createAsyncThunk('user/addPaymentProfile', async (info: CreditCard, { dispatch }) => {
  await httpClient.post('/userpaymentprofiles', info);
  dispatch(getPaymentProfiles());
});

export const removePaymentProfile = createAsyncThunk(
  'user/removePaymentProfile',
  async (card: PaymentProfile, { dispatch, getState }) => {
    await httpClient.delete(`/userpaymentprofiles/${card.guid}`);
    if (card.isDefault) {
      const state = getState() as RootState;
      const candidate = state.user.paymentProfiles?.find((c) => c.guid !== card.guid);
      if (candidate) {
        await dispatch(makeDefaultPaymentProfile(candidate.guid));
      }
    }
    dispatch(getPaymentProfiles());
  }
);

export const makeDefaultPaymentProfile = createAsyncThunk(
  'user/makeDefaultPaymentProfile',
  async (guid: string, { dispatch }) => {
    await httpClient.patch(`/userpaymentprofiles/${guid}`, { isDefault: true });
    dispatch(getPaymentProfiles());
  }
);

export const getCryptoWallets = createAsyncThunk('user/getCryptoWallets', async () => {
  const resp = await httpClient.get(`/users/current/usercryptowallets`);
  return resp.data;
});

export interface AddCryptoWalletPayload {
  wallet: CryptoWallet;
  raiseError?: boolean;
  onSuccess?: () => void;
}

export const addCryptoWallet = createAsyncThunk(
  'user/addCryptoWallet',
  async ({ wallet, onSuccess, raiseError = false }: AddCryptoWalletPayload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const wallets = state.user.cryptoWallets;
    const found = wallets.find((w: CryptoWallet) => w.publicAddress === wallet.publicAddress);
    if (found) {
      if (raiseError) throw new Error('Already registered');
      return;
    }
    try {
      await httpClient.post('/users/current/usercryptowallets', wallet);
      dispatch(getCryptoWallets());
      onSuccess && onSuccess();
    } catch (e) {
      if (raiseError) {
        dispatch(userSlice.actions.setConnectingValue(false));
        throw e;
      }
    }
  }
);

export const deleteCryptoWallet = createAsyncThunk('user/deleteCryptoWallet', async (guid: string, { dispatch }) => {
  await httpClient.delete(`/usercryptowallets/${guid}`);
  dispatch(getCryptoWallets());
});

export const makeDefaultCryptoWallet = createAsyncThunk(
  'user/makeDefaultCryptoWallet',
  async (guid: string, { dispatch }) => {
    await httpClient.patch(`/usercryptowallets/${guid}`, { isDefault: true });
    dispatch(getCryptoWallets());
  }
);

export const changePassword = createAsyncThunk('user/changePassword', async (payload: PasswordChangeRequest) => {
  return (await httpClient.post(`/users/password/change`, payload)).data;
});

export const updateUserProfileImage = createAsyncThunk(
  'user/updateUserProfileImage',
  async ({ userGuid, file }: { userGuid: string; file: FormData }) => {
    const response = await httpClient.post(`/users/${userGuid}/profileimage/upload`, file);
    return response.data;
  }
);

export interface UserState {
  status: string;
  user?: User;
  counter: number;
  paymentProfiles?: PaymentProfile[];
  cryptoWallets: CryptoWallet[];
  error: string;
  newCardInfo?: CreditCard;
  connecting: boolean;
  isChangePasswordSuccess?: boolean;
  isUserProfileImageUpdated?: boolean;
  isUserProfileUpdated?: boolean;
  popupState: null | 'login' | 'register';
}

const initialState: UserState = {
  status: 'idle',
  cryptoWallets: [],
  error: '',
  counter: 0,
  connecting: false,
  popupState: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateNewCardInfo: (state, action) => {
      state.newCardInfo = action.payload.card;
      return state;
    },
    setConnectingValue: (state, action) => {
      state.connecting = action.payload;
      return state;
    },
    setPopupState: (state, action) => {
      state.popupState = action.payload;
      return state;
    },
    resetUserUpdate: (state) => {
      state.isUserProfileUpdated = false;
      state.isUserProfileImageUpdated = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isChangePasswordSuccess = true;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.user = action.payload;
      })
      .addCase(getCryptoWallets.fulfilled, (state, action) => {
        state.cryptoWallets = action.payload;
      })
      .addCase(getPaymentProfiles.fulfilled, (state, action) => {
        state.paymentProfiles = action.payload;
      })
      .addCase(updateUserProfileImage.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isUserProfileImageUpdated = true;
      })
      .addCase(updateUserProfile.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isUserProfileUpdated = true;
      })
      .addMatcher(
        (action): action is PendingAction => /user\/(.*)\/pending/.test(action.type),
        (state) => {
          state.error = '';
          state.counter += 1;
          state.status = 'pending';
          state.isChangePasswordSuccess = undefined;
        }
      )
      .addMatcher(
        (action): action is FulfilledAction => /user\/(.*)\/fulfilled/.test(action.type),
        (state) => {
          state.counter -= 1;
          if (state.counter === 0) state.status = 'fulfilled';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => /user\/(.*)\/rejected/.test(action.type),
        (state, action) => {
          state.counter -= 1;
          state.status = 'error';
          state.error = action?.error?.message || GENERAL_ERR_MSG;
        }
      );
  }
});
export const { updateNewCardInfo, setConnectingValue, setPopupState, resetUserUpdate } = userSlice.actions;
export default userSlice.reducer;
