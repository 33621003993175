import React from 'react';
import classnames from 'classnames/bind';

import styles from './TermsOfUse.module.scss';

const cx = classnames.bind(styles);

const TermsOfUse = () => {
  return (
    <div className={cx('container', 'ocean', 'pt-6')}>
      <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-3')}>
        CountOnUs Foundation Terms of Use
      </div>
      <div className={cx('mt-4')}>
        <div className={cx('has-text-weight-bold has-text-primary is-size-5 mt-5')}>
          CountOnUs Foundation Terms of Use Last revised: August22, 2022
        </div>
        <div className={cx('mt-4')}>
          The CountOnUs Foundation is a platform that allows you to donate to the CountOnUs Foundation, a 501 (c)3
          non-profit organization. These Terms of Use are entered into by and between you and CountOnUs Foundation
          (“Company,” “we,” or “us”). The following terms and conditions, together with any documents they expressly
          incorporate by reference, govern your access to and use of CountOnUs, including any content, functionality,
          and services offered on or through https://www.countonusfoundation.org (the “Site”).
        </div>
      </div>
      <div className={cx('mt-4')}>
        <ol className={cx('terms-list')}>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4')}>Accepting these Terms</div>
            <div className={cx('mt-4')}>
              These Terms of Use set out your rights and responsibilities when you use CountOnUs to donate. Please read
              them carefully. By clicking on the “I Accept” button, completing the account registration process, or
              using the Site, you accept and agree to be bound and abide by these Terms of Use and all of the terms
              incorporated herein by reference. By agreeing to these terms, you hereby certify that you are at least 18
              years of age. If you do not agree to these Terms of Use, you must not access or use the Site.
            </div>
            <div className={cx('mt-4')}>
              Please note that this Agreement contains an arbitration clause and class action waiver. By agreeing to
              these Terms of Use, you agree to resolve all disputes through binding individual arbitration, which means
              that you waive any right to have the dispute decided by a judge or jury, and you waive any right to
              participate in collective action, whether that be a class action, class arbitration, or representative
              action.
            </div>
            <div className={cx('mt-4', 'mb-4')}>
              CountOnUs reserves the right to change or modify these Terms of Use at any time and in our sole
              discretion. You agree and understand that by logging into your account, accessing or using the Site
              following any change to these Terms of Use, you agree to the revised Terms of Use and all of the terms
              incorporated therein by reference. We encourage you to review the Terms of Use from time to time to ensure
              that you understand the terms and conditions that apply when you access or use the Site.
            </div>
          </li>

          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4')}>Your CountOnUs Account</div>
            <div className={cx('mt-4')}>
              You need to create an account with CountOnUs to use the Services. When you create an account, we will ask
              your for some information about yourself. We may require you, in our sole discretion, to provide
              additional information and/or documents. If you do not provide complete and accurate information and/or
              documents in response to such a request, we may refuse to provide you with the Services.
            </div>
            <div className={cx('mt-4')}>
              Your CountOnUs account is subject to the following conditions:
              <ul className={cx('unlisted-list')}>
                <li className={cx('item-list')}>
                  Access: you understand and agree that access to your CountOnUs account is limited solely to you. You
                  agree that you will not sell, rent, lease, or grant access to your CountOnUs account to any person
                  without our prior written permission.
                </li>
                <li className={cx('item-list')}>
                  Security: you understand and agree that you are solely responsible for maintaining the security of
                  your account and control over any user names, passwords, or any other codes that you use to access the
                  Services. You understand and agree that you will not hold us responsible for managing and maintaining
                  the security of your account. You further understand and agree that we are not responsible (and you
                  will not hold us responsible) for any unauthorized access to or use of your account. You are
                  responsible for monitoring your account. If you notice any unauthorized or suspicious activity in your
                  account, please notify us immediately.
                </li>
                <li className={cx('item-list', 'mb-4')}>
                  Communication: you agree and understand that we will communicate with you via electronic means. To
                  ensure that you receive all of our communications, you agree to keep your email address and telephone
                  number current and notify us if there are any changes. You agree that any notices, agreements,
                  disclosures, or other communications delivered to your email address on record is considered valid.
                </li>
                <li className={cx('item-list', 'mb-4')}>
                  Communication: you agree and understand that we will communicate with you via electronic means. To
                  ensure that you receive all of our communications, you agree to keep your email address and telephone
                  number current and notify us if there are any changes. You agree that any notices, agreements,
                  disclosures, or other communications delivered to your email address on record is considered valid.
                </li>
              </ul>
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4')}>Communication</div>
            <div className={cx('mt-4')}>
              <p>
                You agree that we may send you promotional communications by email, including, but not limited to,
                newsletters, special offers, surveys, and other news and information we think will be of interest to
                you.
              </p>
              <p>
                You may opt out of promotional communications at any time by following the instructions provided
                therein.
              </p>
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4')}>Third Parties</div>
            <div className={cx('mt-4')}>
              <p>
                CountOnUs may rely on third-party platforms, including but not limited to MetaMask to perform
                cryptocurrency donations. Our Site may contain links to third-party websites or applications
                (collectively, “Third Party Sites”). CountOnUs does not own or control Third Party Sites. You understand
                and agree that your use of any Third Party Site is subject to any terms of use and/or privacy policy
                provided by such Third Party Site. CountOnUs is not a party to any such agreement. You should review any
                terms of use and/or privacy policy provided by such Third Party Site and should make whatever
                investigation you feel necessary or appropriate before proceeding with any transaction with any third
                party.
              </p>
              <p>
                CountOnUs provides these Third Party Sites only as a convenience and does not review, approve, monitor,
                endorse, warrant, or make any representations with respect to Third Party Sites, or their products or
                services. You use all links in Third Party Sites at your own risk. When you leave our Site, our
                Agreement and policies no longer govern. We may, but are not obligated to, warn you that you are leaving
                our Site.
              </p>
              <p>
                Some transactions on our site may be facilitated and run by Metamask. Any such transactions are governed
                by the Metamask’s Terms of Service and Privacy Policy.
              </p>
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4')}>Disclaimers</div>
            <div className={cx('mt-4')}>
              <p>
                EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY COUNTONUS, THE SITE, CONTENT CONTAINED
                THEREIN, AND NFTS LISTED THEREIN ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES
                OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED. COUNTONUS (AND ITS SUPPLIERS) MAKE NO WARRANTY
                THAT THE SITE WILL (1) MEET YOUR REQUIREMENTS; (2) BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR
                ERROR-FREE BASIS; OR (3) BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR SAFE.
              </p>
              <p>
                WE WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION TAKEN OR TAKEN IN RELIANCE ON MATERIAL OR
                INFORMATION, CONTAINED ON THE SITE. COUNTONUS DOES NOT REPRESENT OR WARRANT THAT CONTENT ON THE SITE IS
                ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE.
              </p>
              <p>
                WHILE COUNTONUS ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF THE SITE AND CONTENT SAFE, COUNTONUS CANNOT
                AND DOES NOT REPRESENT OR WARRANT THAT THE SITE, CONTENT, ANY NFTS LISTED ON OUR SITE OR OUR SERVERS ARE
                FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY DATA THAT YOU
                DISCLOSE ONLINE. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER
                THE INTERNET AND WILL NOT HOLD US RESPONSIBLE FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS
                NEGLIGENCE.
              </p>
              <p>
                WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO RESPONSIBILITY FOR, AND WILL NOT BE
                LIABLE TO YOU FOR, ANY USE OF NFTS INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING
                FROM: (1) USER ERROR SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED
                ADDRESSES; (2) SERVER FAILURE OR DATA LOSS; (3) CORRUPTED WALLET FILES; (4) UNAUTHORIZED ACCESS TO
                APPLICATIONS; (5) ANY UNAUTHORIZED THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF
                VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE SITE OR NFTS.
              </p>
              <p>
                NFTS ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE
                ETHEREUM NETWORK. ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY UNIQUE DIGITAL ASSET OCCURS ON THE
                DECENTRALIZED LEDGER WITHIN THE ETHEREUM PLATFORM. WE DO NOT GUARANTEE THAT COUNTONUS OR ANY COUNTONUS
                PARTY CAN EFFECT THE TRANSFER OF TITLE OR RIGHT IN ANY NFTS.
              </p>
              <p>
                Nothing in this Agreement shall exclude or limit liability of either party for fraud, death or bodily
                injury caused by negligence, violation of laws, or any other activity that cannot be limited or excluded
                by legitimate means.
              </p>
              <p>
                TO THE FULLEST EXTENT PROVIDED BY LAW, COUNTONUS HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AS TO THE SITE AND CONTENT
                CONTAINED THEREIN.
              </p>
              <p>
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4')}>Limitation of Liability</div>
            <div className={cx('mt-4')}>
              <p>
                TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL COUNTONUS BE LIABLE TO YOU OR ANY THIRD PARTY
                FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES
                ARISING FROM THIS AGREEMENT, THE SITE, PRODUCTS OR THIRD PARTY SITES AND PRODUCTS, OR FOR ANY DAMAGES
                RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS
                OF GOODWILL, OR LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR
                OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF COUNTONUS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. ACCESS TO, AND USE OF, THE SITES, PRODUCTS OR THIRD PARTY SITES AND PRODUCTS ARE AT YOUR OWN
                DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE
                DEVICE OR LOSS OF DATA RESULTING THEREFROM.
              </p>
              <p>
                NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE MAXIMUM AGGREGATE
                LIABILITY OF COUNTONUS ARISING OUT OF OR IN ANY WAY RELATED TO THIS AGREEMENT, THE ACCESS TO AND USE OF
                THE SITE, CONTENT, NFTS, OR ANY PRODUCTS OR SERVICES PURCHASED ON THE SITE EXCEED THE GREATER OF (A)
                $100 OR(B) THE AMOUNT YOU HAVE PAID TO COUNTONUS FOR THE SERVICES IN THE LAST SIX MONTHS OUT OF WHICH
                LIABILITY AROSE. THE FOREGOING LIMITATIONS OF LIABILITY SHALL NOT APPLY TO LIABILITY OF COUNTONUS FOR
                (A) DEATH OR PERSONAL INJURY CAUSED BY A MEMBER OF COUNTONUS’S NEGLIGENCE; OR FOR (B) ANY INJURY CAUSED
                BY A MEMBER OF COUNTONUS’S FRAUD OR FRAUDULENT MISREPRESENTATION.
              </p>
              <p>
                Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so
                the above limitation or exclusion may not apply to you. Some jurisdictions also limit disclaimers or
                limitations of liability for personal injury from consumer products, so this limitation may not apply to
                personal injury claims.
              </p>
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4')}>Indemnification</div>
            <div className={cx('mt-4')}>
              <p>
                To the fullest extent permitted by applicable law, you agree to indemnify, defend and hold harmless
                CountOnUs, and our respective past, present and future employees, officers, directors, contractors,
                consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries,
                affiliates, agents, representatives, predecessors, successors and assigns (individually and
                collectively, the “CountOnUs Parties”), from and against all actual or alleged third party claims,
                damages, awards, judgments, losses, liabilities, obligations, penalties, interest, fees, expenses
                (including, without limitation, attorneys’ fees and expenses) and costs (including, without limitation,
                court costs, costs of settlement and costs of pursuing indemnification and insurance), of every kind and
                nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected
                or unsuspected, in law or equity, whether in tort, contract or otherwise (collectively, “Claims”),
                including, but not limited to, damages to property or personal injury, that are caused by, arise out of
                or are related to (a) your use or misuse of the Site, Content or NFTs, (b) any Feedback you provide, (c)
                your violation of these Terms of Use, (d) and your violation of the rights of a third party, including
                another user or MetaMask. You agree to promptly notify CountOnUs of any third party Claims and cooperate
                with the CountOnUs Parties in defending such Claims. You further agree that the CountOnUs Parties shall
                have control of the defense or settlement of any third party Claims. THIS INDEMNITY IS IN ADDITION TO,
                AND NOT IN LIEU OF, ANY OTHER INDEMNITIES SET FORTH IN A WRITTEN AGREEMENT BETWEEN YOU AND COUNTONUS.
              </p>
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4')}>Governing Law</div>
            <div className={cx('mt-4')}>
              <p>
                These Terms of Use, your use of Site, your rights and obligations, and all actions contemplated by,
                arising out of or related to these Terms of Use shall be governed by the laws of the State of
                California, as if these Terms of Use are a contract wholly entered into and wholly performed within the
                State of California. YOU UNDERSTAND AND AGREE THAT YOUR USE OF COUNTONUS AS CONTEMPLATED BY THESE TERMS
                OF USE SHALL BE DEEMED TO HAVE OCCURRED IN THE STATE OF CALIFORNIA AND BE SUBJECT TO THE INTERNAL LAWS
                OF THE STATE OF CALIFORNIA WITHOUT REGARD TO ITS CONFLICTS OF LAWS PROVISIONS.
              </p>
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4')}>Disputes</div>
            <div className={cx('mt-4')}>
              <p>
                Please read the following agreement to arbitrate (“Arbitration Agreement”) in its entirety. This clause
                requires you to arbitrate disputes with CountOnUs and limits the manner in which you can seek relief
                from us.
              </p>
              <p>
                You agree that any dispute or claim relating in any way to: your access, use, or attempted access or use
                of the Site; or any aspect of your relationship with CountOnUs will be resolved by binding arbitration,
                except that (1) you may assert claims in small claims court if your claims qualify; and (2) you or
                CountOnUs may seek equitable relief in court for infringement or other misuse of intellectual property
                rights (such as trademarks, trade dress, domain names, trade secrets, copyright, or patent). You agree
                that any such arbitration shall be settled solely and exclusively by binding arbitration held in
                California, administered by JAMS and conducted in English, rather than in court.
              </p>
              <p>
                Any such arbitration shall be conducted in accordance with the then-prevailing JAMS Streamlined
                Arbitration Rules & Procedures, except that any dispute involving claims and counterclaims over
                $250,000, not inclusive of attorneys’ fees and interest, shall be subject to the then-prevailing JAMS
                Comprehensive Arbitration Rules and Procedures.
              </p>
              <p>
                The arbitrator shall have exclusive authority to (1) determine the scope and enforceability of this
                Arbitration Agreement; and (2) resolve any dispute related to the interpretation, applicability,
                enforceability or formation of this Arbitration Agreement, including but not limited to any claim that
                all or part of this Arbitration Agreement is void or voidable; (3) decide the rights and liabilities, if
                any, of you and CountOnUs; (4) grant motions dispositive of all or part of any claim; (5) award monetary
                damages and grant any non-monetary remedy or relief available to a party under applicable law,
                arbitration rules, and these Terms of Use (including the Arbitration Agreement). The arbitrator has the
                same authority to award relief on an individual basis that a judge in a court of law would have. The
                arbitrator shall issue a written award and statement of decision describing the essential findings and
                conclusions on which the award is based, including the calculation of any damages awarded. Such an award
                is final and binding upon you and us.
              </p>
              <p>
                You understand that by agreeing to this Arbitration Agreement, you and CountOnUs are each waiving their
                right to trial by jury and to participate in a class action or class arbitration.
              </p>
              <p>
                If any part of this Arbitration Agreement is found to be invalid or unenforceable, then such part shall
                be of no force and effect and shall be severed and the remainder of the Arbitration Agreement shall
                continue to in full force and effect.
              </p>
              <p>This Arbitration Agreement shall survive the termination of your relationship with CountOnUs.</p>
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4')}>Termination</div>
            <div className={cx('mt-4')}>
              <p>
                We reserve the right, without notice and in our sole discretion, to terminate your license to access or
                use the Site or Content, at any time and for any reason. You understand and agree that we shall have no
                liability or obligation to you in such an event.
              </p>
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4')}>Severability</div>
            <div className={cx('mt-4')}>
              <p>
                If any term, clause, or provision of these Terms of Use is held invalid or unenforceable, then that
                term, clause, or provision shall be severable from these Terms of Use and will not affect the validity
                or enforceability of any remaining part of that term, cause, or provision, or any other terms, clause,
                or provision of these Terms of Use.
              </p>
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4')}>Entire Agreement</div>
            <div className={cx('mt-4')}>
              <p>
                These Terms of Use comprise the entire agreement between you and CountOnUs relating to your access to
                and use of the Site and Content, and supersede any and all prior discussions agreements, and
                understandings of any kind (including without limitation prior versions of this User Agreement). Except
                as otherwise provided herein, these Terms of Use are intended solely for the benefit of the parties and
                are not intended to confer third party beneficiary rights upon any other person or entity.
              </p>
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4')}>Survival</div>
            <div className={cx('mt-4')}>
              <p>
                You agree and understand that all provisions of these Terms of Use shall survive the termination or
                expiration of these Terms of Use.
              </p>
            </div>
          </li>
          <li className={cx('primary-list')}>
            <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-4')}>Contact Information</div>
            <div className={cx('mt-4', 'mb-4')}>
              <p>
                If you have any questions, would like to provide feedback, or would like more information about
                CountOnUs, please feel free to email us at support@CountOnUs.com. If you would like to lodge a
                complaint, please contact us at support@CountOnUs.com.
              </p>
              <p>END OF AGREEMENT</p>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsOfUse;
