import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';

import styles from './Checkbox.module.scss';

const cn = classnames.bind(styles);

interface CheckboxProps {
  label?: string;
  name: string;
  onChange?: (value: boolean) => unknown;
  terms?: boolean;
  termsPrivacy?: boolean;
}

const Checkbox = (props: CheckboxProps) => {
  const [isSelected, setIsSelected] = useState(false);

  const toggleIsSelected = () => {
    setIsSelected(!isSelected);
  };

  useEffect(() => {
    if (props.onChange) {
      props.onChange(isSelected);
    }
  }, [isSelected]);

  return (
    <div className={cn('control')} onClick={toggleIsSelected}>
      <div className={cn('flexbox')}>
        <div className={cn('checkbox', { selected: isSelected })}>
          <i className="material-icons-outlined">{isSelected ? 'check_box' : 'check_box_outline_blank'}</i>
        </div>
        <label className={cn('label')}>
          {props.label}
          {props.terms && (
            <span className={cn('is-link')}>
              <Link target={'_blank'} to="/terms-of-use">
                {''} Terms and Conditions
              </Link>
            </span>
          )}
          {props.termsPrivacy && (
            <div>
              By signing up, you agree to the{' '}
              <span className={cn('is-link')}>
                <Link target={'_blank'} to="/terms-of-use">
                  Terms and Conditions
                </Link>
              </span>{' '}
              and{' '}
              <span className={cn('is-link')}>
                <Link target={'_blank'} to="/privacy-policy">
                  Privacy Policy
                </Link>
              </span>{' '}
              and to receive periodic updates.
            </div>
          )}
        </label>
      </div>
      <input className={cn('hidden')} type="checkbox" name={props.name} checked={isSelected} readOnly />
    </div>
  );
};

export default Checkbox;
