import React from 'react';
import classnames from 'classnames/bind';
import { Post } from 'types';
import styles from './PurchaseInfo.module.scss';

const cx = classnames.bind(styles);

export interface PurchaseInfoProps {
  item?: Post;
  currency?: string;
  //collection?: Post;
}

const PurchaseInfo = ({ item, currency }: PurchaseInfoProps) => (
  <div className={cx('purchase-information')}>
    <div className="is-size-4 has-text-weight-bold mb-4">Donation Information</div>
    <div className="flexbox align-start mb-6">
      <img className={cx('thumbnail')} src={item?.contentUrl} />
      <div className={cx('ml-4')}>
        <div>
          {/*<div className="serif">
            <div className="is-size-3 has-text-weight-bold">{item?.name}</div>
          </div>

          <div>
            <div className="flexbox flex">
              <object
                className={cx('avatar', 'collaborator-avatar', 'mx-1')}
                data={item?.marketplaceListingSellerUserProfileImageUrl || defaultAvatar}
                style={{ backgroundColor: colorGenerator(item?.userUsername || '') }}
              />
              <span>{collection?.merchantName}</span>
            </div>
          </div>

          <div className={cx('my-4')}>{item?.description}</div>*/}

          {/* <div>{item?.marketplaceListingPriceType === 'BestOffer' ? 'Bid Price' : 'Donation Amount'}:</div> */}
          {currency === 'USD' ? (
            <div className={cx('is-size-2', 'has-text-weight-bold')}>
              {item?.marketplaceListingUSDAmount ? `$${item.marketplaceListingUSDAmount}` : ''}
            </div>
          ) : (
            <div className={cx('is-size-2', 'has-text-weight-bold')}>
              {item?.marketplaceListingETHAmount ? `${item.marketplaceListingETHAmount.toFixed(3)} ETH` : ''}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default PurchaseInfo;
