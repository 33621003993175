import { httpClient, httpClientFeaturedPost } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { PendingAction, RejectedAction } from 'state/store';
import { Post, PostNft, MarketlistingNFT, PurchaseItemPayload, ExchangeRates } from 'types/post';
import { cloneDeep } from 'lodash';
import { GENERAL_ERR_MSG } from 'utils/copies';
import { Bid } from 'types/bid';
import { View } from 'types/view';
import { PLATFORM } from 'types';
import { openSnackbar } from './snackbarReducer';
import { setConnectingValue } from './userReducer';

export const fetchFeaturedCollection = createAsyncThunk('collection/fetchFeaturedCollection', async () => {
  const response = await httpClientFeaturedPost.get('/public/featured/posts', {
    params: {
      platform: PLATFORM,
      postType: 'PostGroup'
    }
  });
  return response.data;
});

export const fetchCollections = createAsyncThunk('collection/fetchCollections', async () => {
  const response = await httpClient.get('/public/posts/search', {
    params: {
      platform: PLATFORM,
      postType: 'PostGroup',
      postGroupType: 'Collection'
    }
  });
  return response.data;
});

export const fetchCollectionItemWithNftListing = createAsyncThunk(
  'collection/fetchCollectionItemWithNftListing',
  async (guid: string) => {
    return {
      guid: guid,
      data: await httpClient
        .get('/posts/search', {
          params: {
            platform: PLATFORM,
            postGroupGUID: guid,
            isPublished: true
          }
        })
        .then((response) => {
          const selectedCollection: Post[] = response.data;
          return new Promise<Post[]>((resolve) => {
            Promise.allSettled(
              selectedCollection.map((c: Post) =>
                httpClient.get('/marketplacelistings/search', {
                  params: { platform: PLATFORM, postGUID: c.guid }
                })
              )
            ).then((promise) => {
              const collection = cloneDeep(selectedCollection);
              promise.forEach((item, index: number) => {
                if (item.status === 'fulfilled') {
                  collection[index].marketListing = item.value.data[0];
                  resolve(collection);
                }
              });
            });
          });
        })
    };
  }
);

export const fetchCollectionById = createAsyncThunk('collection/fetchCollectionById', async (guid: string) => {
  const response = await httpClient.get('/posts/search', {
    params: {
      platform: PLATFORM,
      postType: 'PostGroup',
      guid: guid
    }
  });
  return response.data;
});

export const fetchCollectionItem = createAsyncThunk('collection/fetchCollectionItem', async (guid: string) => {
  const response = await httpClient.get('/posts/search', {
    params: {
      platform: PLATFORM,
      postGroupGUID: guid,
      isPublished: true
    }
  });
  return {
    guid: guid,
    data: response.data
  };
});

export const fetchViewCollection = createAsyncThunk(
  'collection/fetchViewViewCollection',
  async (postGroupGUID: string) => {
    const response = await httpClient.get('/public/posts/search', {
      params: {
        platform: PLATFORM,
        postGroupGUID: postGroupGUID
      }
    });
    return response.data;
  }
);

export const fetchItem = createAsyncThunk('collection/fetchItem', async (guid: string) => {
  const response = await httpClient.get('/posts/search', {
    params: {
      platform: PLATFORM,
      guid: guid
    }
  });
  return response.data;
});

export const fetchNftInformation = createAsyncThunk('collection/fetchNftInformation', async (guid: string) => {
  const response = await httpClient.get(`/posts/${guid}/nft`, {});
  return response.data;
});

export const fetchMarketPlaceListings = createAsyncThunk('collection/fetchMarketPlaceListings', async () => {
  const response = await httpClient.get('/marketplacelistings', {});
  return response.data;
});

export const searchMarketplaceListing = createAsyncThunk(
  'collection/searchMarketPlaceListing',
  async (param: { guid?: string; postGuid?: string }) => {
    const response = await httpClient.get('/marketplacelistings/search', {
      params: param
    });
    return response.data[0];
  }
);

export const getBidItem = createAsyncThunk('collection/getBidItem', async (item: MarketlistingNFT) => {
  const response = await httpClient.get(`/marketplacelistings/${item.guid}/bids`, {
    params: item
  });
  return response.data;
});

export const getExchangeRate = createAsyncThunk(
  'collection/getExchangeRate',
  async (marketplaceListingGUID: string) => {
    const response = await httpClient.get(`/marketplacelistings/${marketplaceListingGUID}/exchangerates`);
    return response.data;
  }
);
export interface PurchaseItemProp {
  marketplaceListingGUID: string;
  payload: PurchaseItemPayload;
  navigate: NavigateFunction;
}

export const bidItem = createAsyncThunk(
  'collection/bidItem',
  async (
    data: {
      marketplaceListingGUID: string;
      payload: Bid;
      navigate: NavigateFunction;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setConnectingValue(true));
      const response = await httpClient.post(`/marketplacelistings/${data.marketplaceListingGUID}/bids`, data.payload);
      dispatch(
        openSnackbar({
          message: 'Bid successfully placed!',
          type: 'success'
        })
      );
      data.navigate('/account/collections');
      return response.data;
    } catch (err: unknown) {
      dispatch(
        openSnackbar({
          message: 'An error has occurred. Please try again later.',
          type: 'danger'
        })
      );
      return rejectWithValue(err);
    } finally {
      dispatch(setConnectingValue(false));
    }
  }
);

export const purchaseItem = createAsyncThunk(
  'collection/purchaseItem',
  async (data: PurchaseItemProp, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setConnectingValue(true));
      const response = await httpClient.post(
        `/marketplacelistings/${data.marketplaceListingGUID}/purchase`,
        data.payload
      );
      dispatch(
        openSnackbar({
          message: 'Successfully purchased!',
          type: 'success'
        })
      );
      data.navigate('/account/collections');
      return response.data;
    } catch (err: unknown) {
      dispatch(
        openSnackbar({
          message: 'Unable to continue. Please check that your wallet or card is connected and has sufficient balance.',
          type: 'danger'
        })
      );
      return rejectWithValue(err);
    } finally {
      dispatch(setConnectingValue(false));
    }
  }
);

export const fetchMerchantCollections = createAsyncThunk(
  'merchant/fetchMerchantCollections',
  async (merchantGUID: string) => {
    const response = await httpClient.get('/posts/search', {
      params: {
        platform: PLATFORM,
        postType: 'PostGroup',
        postGroupType: 'Collection',
        merchantGUID: merchantGUID,
        isPublished: true
      }
    });
    return { merchantGUID, response: response.data };
  }
);

export const createView = createAsyncThunk('view/createView', async (views: View) => {
  const response = await httpClient.post(`/views`, views);
  return response.data;
});

export const fetchExchangeRates = createAsyncThunk('post/fetchExchangeRates', async (guid: string) => {
  const response = await httpClient.get(`/marketplacelistings/${guid}/exchangerates`, {});
  return response.data;
});

interface PostState {
  status: string;
  featuredCollection?: Post;
  collections?: Post[];
  collection?: Post;
  merchantCollections?: Post[];
  items?: Post[];
  item?: Post;
  nftInformation?: PostNft;
  marketPlaceListings?: MarketlistingNFT[];
  viewMarketPlaceItem?: MarketlistingNFT;
  error?: string;
  exchangeRates?: ExchangeRates;
}

const initialState: PostState = {
  status: 'idle'
};

const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
      return state;
    },
    setCollection: (state, action) => {
      state.collection = action.payload;
    },
    setMerchantCollection: (state, action) => {
      state.merchantCollections = action.payload;
    },
    setItem: (state, action) => {
      state.item = action.payload;
    },
    resetCollection: (state) => {
      state.collection = undefined;
      return state;
    },
    resetItem: (state) => {
      state.item = undefined;
      state.nftInformation = undefined;
    },
    resetCollectionAndItems: (state) => {
      state.items = undefined;
      state.collection = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeaturedCollection.fulfilled, (state, action) => {
        state.status = 'success';
        state.featuredCollection = action.payload[0];
      })
      .addCase(fetchCollections.fulfilled, (state, action) => {
        state.status = 'success';
        state.collections = action.payload;
      })
      .addCase(fetchCollectionItemWithNftListing.fulfilled, (state, action) => {
        state.status = 'success';
        const guid = action.payload.guid;
        const collections = cloneDeep(state.collections);
        const index = collections?.findIndex((c) => c.postGroupGUID === guid);
        if (collections && index) {
          collections[index] = action.payload.data[0];
          state.collections = collections;
        }
        const collection = cloneDeep(state.collection);
        if (collection) {
          collection.items = action.payload.data;
          state.collection = collection;
        }
      })
      .addCase(fetchCollectionById.fulfilled, (state, action) => {
        state.status = 'success';
        state.collection = action.payload[0];
      })
      .addCase(fetchCollectionItem.fulfilled, (state, action) => {
        state.status = 'success';
        const guid = action.payload.guid;
        const collections = cloneDeep(state.collections);
        const index = collections?.findIndex((c) => c.postGroupGUID === guid);
        if (collections && index) {
          collections[index] = action.payload.data[0];
          state.collections = collections;
        }
        const collection = cloneDeep(state.collection);
        if (collection) {
          collection.items = action.payload.data;
          state.collection = collection;
        }
      })
      .addCase(fetchViewCollection.fulfilled, (state, action) => {
        state.status = 'success';
        state.items = action.payload;
      })
      .addCase(fetchItem.fulfilled, (state, action) => {
        state.status = 'success';
        state.item = action.payload[0];
      })
      .addCase(fetchNftInformation.fulfilled, (state, action) => {
        state.nftInformation = action.payload;
      })
      .addCase(fetchMarketPlaceListings.fulfilled, (state, action) => {
        state.marketPlaceListings = action.payload;
      })
      .addCase(searchMarketplaceListing.fulfilled, (state, action) => {
        state.viewMarketPlaceItem = action.payload;
      })
      .addCase(getBidItem.fulfilled, (state) => {
        state.status = 'success';
      })
      .addCase(createView.fulfilled, (state) => {
        state.status = 'success';
      })
      .addCase(bidItem.rejected, (state) => {
        state.error = 'rejected';
      })
      .addCase(bidItem.fulfilled, (state) => {
        state.status = 'success';
      })
      .addCase(purchaseItem.rejected, (state) => {
        state.error = 'rejected';
      })
      .addCase(purchaseItem.fulfilled, (state) => {
        state.status = 'success';
      })
      .addCase(fetchMerchantCollections.fulfilled, (state, action) => {
        state.status = 'success';
        state.merchantCollections = action.payload.response;
      })
      .addCase(fetchExchangeRates.fulfilled, (state, action) => {
        if (state.item) {
          if (!state.item?.marketplaceListingETHAmount) {
            state.item.marketplaceListingETHAmount = action.payload.exchangeRates.Eth;
            state.item.nftCurrency = 'USD';
          }

          if (!state.item?.marketplaceListingUSDAmount) {
            state.item.marketplaceListingUSDAmount = action.payload.exchangeRates.Usd;
            state.item.nftCurrency = 'ETH';
          }
        }
        state.exchangeRates = action.payload.exchangeRates;
      })
      .addMatcher(
        (action): action is PendingAction => /collection\/(.*)\/pending/.test(action.type),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => /collection\/(.*)\/rejected/.test(action.type),
        (state, action) => {
          state.error = 'rejected';
          state.error = action?.error?.message || GENERAL_ERR_MSG;
        }
      );
  }
});

export const { resetCollectionAndItems, setCollection, resetCollection, resetItem, setMerchantCollection } =
  postSlice.actions;
export default postSlice.reducer;
