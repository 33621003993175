import React from 'react';
import classnames from 'classnames/bind';

import Verification from 'components/Verification';
import styles from './Verification.module.scss';

const cx = classnames.bind(styles);

function VerificationPage() {
  // const [loginState, setLoginState] = useState('login');

  return (
    <div className={cx('container', 'stretch')}>
      <div className={cx('wrapper')}>
        <Verification />
      </div>
    </div>
  );
}

export default VerificationPage;
