export interface User {
  guid?: string;
  profileImageUrl?: string;
  phoneNumber?: string;
  displayName: string;
  emailAddress: string;
  username: string;
  firstName: string;
  lastName: string;
  description?: string;
}

export interface PaymentProfile {
  guid: string;
  userPaymentConfigurationGUID: string;
  creditCardType: string;
  expirationDate: string;
  description: string;
  isDefault: boolean;
  maskedNumber: string;
}

export interface CreditCard {
  description?: string;
  creditCardNumber: string;
  expirationDate: string;
  cardCode: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode: string;
  isDefault: boolean;
}

export enum WalletSource {
  METAMASK = 'MetaMask'
}

export enum CryptoType {
  ETHEREUM = 'Ethereum'
}
export interface CryptoWallet {
  guid?: string;
  platform?: string;
  source: WalletSource;
  blockchainType: CryptoType;
  publicAddress: string;
  isDefault?: boolean;
  balance?: number;
  isManaged: boolean;
}

export interface PurchasedMarketplaceListing {
  guid: string;
  buyerUserDisplayName: string;
  buyerUserFirstName: string;
  buyerUserGUID: string;
  buyerUserLastName: string;
  buyerUserUsername: string;
  dateCreated: string;
  dateLastRenewed: string;
  postBackstoryDescription: string;
  postContentBLOBGUID: string;
  postContentUrl: string;
  postDescription: string;
  postGUID: string;
  postName: string;
  postNormalizedContentBLOBGUID: string;
  postNormalizedContentUrl: string;
  preferredCurrency: string;
  priceType: string;
  sellerUserDisplayName: string;
  sellerUserGUID: string;
  sellerUserUsername: string;
  status: string;
  tokenProfileKey: string;
  usdAmount?: number;
  ethAmount?: number;
  dateSold: string;
}

export interface GiftPayload {
  toUserGUID: string;
  giftType: 'Gift';
  message?: string;
  usdAmount: number;
  paymentProfileGUID?: string;
  creditCard?: CreditCard;
  saveCreditCard?: boolean;
}
