import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import classnames from 'classnames/bind';

import { Post } from 'types';
import Skeleton from 'components/Skeleton';
import { useNavigate } from 'react-router-dom';
import { resetItem } from 'state/reducers/postReducer';
import { fetchMarketplaceItems } from 'state/reducers/marketPlaceReduer';
import PostItem from 'components/PostItem';
import { RootState } from 'state/store';

import styles from './Marketplace.module.scss';

const cx = classnames.bind(styles);

const Marketplace = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { posts, status } = useSelector((state: RootState) => ({
    posts: state.marketPlace.data,
    status: state.marketPlace.status
  }));

  useEffect(() => {
    dispatch(fetchMarketplaceItems());
  }, []);
  const viewPiece = (post: Post) => {
    dispatch(resetItem());
    navigate(`/marketplace/${post.guid}`);
  };

  return (
    <>
      <div className={cx('header')}>
        <div className={cx('container')}>
          <div className={cx('has-text-centered')}>
            <div className={cx('collaborator', 'is-size-2', 'has-text-weight-bold')}>Marketplace</div>
            <div className={cx('description', 'mt-4')}>
              Purchase NFTs from people looking to resell their exclusive AvantThomas pieces.
            </div>
          </div>
        </div>
      </div>
      <div className={cx('container', 'mt-4')}>
        <div className="trance-wrapper">
          <div className="trance">
            {status === 'success' ? (
              posts.map(
                (post: Post) =>
                  (post.marketplaceListingETHAmount || post.marketplaceListingUSDAmount) && (
                    <PostItem post={post} viewPiece={viewPiece} key={post.guid} />
                  )
              )
            ) : (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Skeleton className="collection-skeleton" type="rect" height="356px" width="calc(25% - 1rem)" />
                <Skeleton className="collection-skeleton" type="rect" height="356px" width="calc(25% - 1rem)" />
                <Skeleton className="collection-skeleton" type="rect" height="356px" width="calc(25% - 1rem)" />
                <Skeleton className="collection-skeleton" type="rect" height="356px" width="calc(25% - 1rem)" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Marketplace;
