import { httpClient } from 'http/httpClient';
import React, { useEffect, useState } from 'react';
import Checkbox from 'components/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import classnames from 'classnames/bind';
import { setPopupState, getPaymentProfiles } from 'state/reducers/userReducer';
import Modal from 'components/Modal';
import CreditCardForm from 'components/CreditCardForm';
import CreditCardList from 'components/CreditCardList';
import { cloneDeep } from 'lodash';
import { PaymentProfile, CreditCard, GiftPayload } from 'types';
import { openSnackbar } from 'state/reducers/snackbarReducer';
import Loading from 'components/Loading';

import styles from './Donation.module.scss';

const cx = classnames.bind(styles);

export interface Props {
  onSuccess: () => void;
}

const CreditCardDonation: React.FC<Props> = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const { loggedIn, user, merchantUserGUID } = useSelector((state: RootState) => ({
    loggedIn: state.session.authToken && state.user.user?.guid,
    user: state.user,
    merchantUserGUID: state.merchant.merchants.length > 0 ? state.merchant.merchants[0].userGUID : undefined
  }));

  const [showConfirm, setShowConfirm] = useState(false);
  const [isSaveCard, setIsSaveCard] = useState(false);
  const [showPopup, setShowPoup] = useState(false);
  const [donateConfirmed, setDonateConfirmed] = useState(false);
  const [amount, setAmount] = useState<50 | 25 | 15 | 10 | undefined>();
  const [customAmount, setCustomAmount] = useState<number | undefined>();
  const [selectedCard, setSelectedCard] = useState<PaymentProfile | null>(null);
  const [isNewCard, setIsNewCard] = useState(false);
  const [creditCardData, setCreditCardData] = useState<CreditCard | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDonateConfirmed(false);
  }, [amount, customAmount]);

  useEffect(() => {
    if (loggedIn && donateConfirmed) {
      setShowPoup(true);
    }
  }, [loggedIn, donateConfirmed]);

  const renderDonationBox = (value: 50 | 25 | 15 | 10) => {
    return (
      <div
        onClick={() => {
          setAmount(value === amount ? undefined : value);
          setCustomAmount(undefined);
        }}
        className={cx('amount', { active: value === amount })}>
        ${value}
      </div>
    );
  };

  const handleCustomAmountChange = (e: React.FormEvent<HTMLInputElement>) => {
    const numericValue = parseInt(e.currentTarget.value);
    if (!isNaN(numericValue)) {
      setCustomAmount(numericValue);
      setAmount(undefined);
    } else {
      setCustomAmount(undefined);
    }
  };

  const handleDonateClick = () => {
    setDonateConfirmed(true);
    if (!loggedIn) {
      dispatch(setPopupState('register'));
    }
  };

  const handleDonate = async () => {
    if (!merchantUserGUID) {
      dispatch(
        openSnackbar({
          message: 'Something went wrong. Please try again later.',
          type: 'error'
        })
      );
      return;
    }
    let payload: GiftPayload = {
      toUserGUID: merchantUserGUID,
      giftType: 'Gift',
      usdAmount: (customAmount || amount) as number
    };

    if (isNewCard || creditCardData) {
      const temp = cloneDeep(creditCardData) as CreditCard;

      temp.creditCardNumber = temp.creditCardNumber.replace(/\s/g, '');
      temp.expirationDate = temp.expirationDate.replace(/\s/g, '');
      payload = {
        ...payload,
        creditCard: temp,
        saveCreditCard: isSaveCard
      };
    } else {
      payload = {
        ...payload,
        paymentProfileGUID: selectedCard?.guid
      };
    }
    try {
      setLoading(true);
      await httpClient.post('/gifts', payload);

      setLoading(false);
      setAmount(undefined);
      setCustomAmount(undefined);
      setDonateConfirmed(false);
      setShowPoup(false);
      dispatch(getPaymentProfiles());
      setShowConfirm(true);
    } catch (e) {
      console.error(e);
      setLoading(false);
      dispatch(
        openSnackbar({
          message: 'Something went wrong. Please try again later.',
          type: 'danger'
        })
      );
    }
  };

  return (
    <div className={cx('card-donation')}>
      <div className={cx('card-content', 'donation-card-content')}>
        <div className={cx('amounts')}>
          <div>{renderDonationBox(10)}</div>
          <div>{renderDonationBox(15)}</div>
          <div>{renderDonationBox(25)}</div>
          <div>{renderDonationBox(50)}</div>

          <div className={cx('field', 'field-amount')}>
            <div className={cx('control', 'has-icons-left')}>
              <input
                type="number"
                value={customAmount}
                onChange={handleCustomAmountChange}
                className={cx('input', 'custom-amount')}
                placeholder="Other"
              />
              <span className={cx('icon is-left', 'icon-dollar')}>$</span>
            </div>
          </div>
        </div>

        <div className={cx('mt-6')}>
          <button
            onClick={handleDonateClick}
            className={cx('button', 'is-fullwidth', 'is-yellow')}
            disabled={!customAmount && !amount}>
            DONATE
          </button>
        </div>
      </div>
      {showConfirm && (
        <Modal
          onClose={() => {
            setShowConfirm(false);
            onSuccess();
          }}>
          <div className="is-flex-direction-column">
            <p className="is-size-4">Thanks for your donation!</p>
            <button className="button" onClick={() => setShowConfirm(false)}>
              OK
            </button>
          </div>
        </Modal>
      )}
      {showPopup && (
        <Modal
          onClose={() => {
            setShowPoup(false);
            setDonateConfirmed(false);
            setIsNewCard(false);
          }}>
          {loading && <Loading />}
          {!isNewCard && user.paymentProfiles && user.paymentProfiles.length > 0 ? (
            <div className={cx('has-text-left')}>
              <div className="is-size-4 has-text-weight-bold mb-4">Card Information</div>
              <div className={cx('card-grid')}>
                {(user?.paymentProfiles || []).map((card: PaymentProfile) => (
                  <div key={card.guid} className={cx('card-cell')}>
                    <CreditCardList
                      isSelected={selectedCard?.guid === card.guid}
                      card={card}
                      size="sm"
                      onSelect={(c) => setSelectedCard(c)}
                    />
                  </div>
                ))}
                {/*        {showAddCardModal && (
                    <Modal onClose={() => setShowAddCardModal(false)}>
                      <CreditCardModal onClose={() => setShowAddCardModal(false)} />
                    </Modal>
                  )}*/}
              </div>

              <div className="mt-2">
                <button
                  disabled={!selectedCard}
                  onClick={handleDonate}
                  className={cx('button is-warning', 'donate-button')}>
                  Donate
                </button>
                <button className="button ml-4" onClick={() => setIsNewCard(true)}>
                  Add New Card
                </button>
              </div>
            </div>
          ) : (
            <div className={cx('has-text-left')}>
              <CreditCardForm data={setCreditCardData} />
              <Checkbox label="Save card for future purchases." name="save" onChange={(e) => setIsSaveCard(e)} />
              <div className={cx('mt-2')}>
                <button type="submit" onClick={handleDonate} className={cx('button is-warning', 'donate-button')}>
                  Donate
                </button>
              </div>
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

export default CreditCardDonation;
