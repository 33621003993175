import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classnames from 'classnames/bind';
import moment from 'moment';
import { fetchMerchantWithCollection } from 'state/reducers/merchantReducer';
import { createView, fetchCollectionById, fetchCollectionItem, fetchItem } from 'state/reducers/postReducer';
import { getPaymentProfiles, setConnectingValue } from 'state/reducers/userReducer';
import { RootState } from 'state/store';

import Timer from 'components/Timer';
import { Post } from 'types';
import PurchaseButton from './PurchaseButton';
import ItemCard from './ItemCard';
import styles from './Item.module.scss';

const cx = classnames.bind(styles);

const Item = () => {
  const { post, merchant } = useSelector((state: RootState) => ({
    post: state.post,
    merchant: state.merchant,
    user: state.user
  }));

  const dispatch = useDispatch();
  const { guid, postGroupGuid } = useParams();

  const incrementViews = (post?: Post) => {
    const view = {
      merchantGUID: post?.merchantGUID,
      postGUID: post?.guid,
      dateViewed: moment().format('MM/DD/YY hh:mm:ss')
    };

    dispatch(createView(view));
  };

  useEffect(() => {
    if (post.collection === undefined && postGroupGuid) {
      dispatch(fetchCollectionById(postGroupGuid));
    }
    if (guid) {
      dispatch(fetchItem(guid));
    }
  }, [guid, postGroupGuid]);

  useEffect(() => {
    dispatch(setConnectingValue(false));
    if (post.item) {
      dispatch(fetchMerchantWithCollection(post.item.merchantGUID));
    }
    incrementViews(post.item);
  }, [post.item]);

  useEffect(() => {
    if (post.collection?.items === undefined && postGroupGuid) {
      dispatch(fetchCollectionItem(postGroupGuid));
    }
  }, [post.collection]);

  useEffect(() => {
    dispatch(getPaymentProfiles());
  }, []);

  return (
    <div className={cx('malibu')}>
      {post.item ? (
        <>
          <div className={cx('container', 'piece')}>
            <div className={cx('columns')}>
              <div className={cx('column', 'is-half')}>
                <div className={cx('serif', 'is-size-2', 'has-text-centered', 'has-text-weight-bold')}>
                  {post.item.name}
                </div>
              </div>
            </div>
            <div className={cx('columns')}>
              <div className={cx('column')}>
                <ItemCard item={post.item} />
                <div className={cx('flexbox', 'justify-between', 'px-4', 'mt-2')}>
                  <div>Blockchain</div>
                  <div>{post.nftInformation?.blockchainType || 'Ethereum'}</div>
                </div>
              </div>
              <div className={cx('column')}>
                <div className={cx('height-full')}>
                  <div className={cx('caustic', 'has-text-centered')}>
                    <div className={cx('item-info', 'is-size-6', 'has-text-left')}>
                      <div className={cx('header')}>About Your Donation</div>
                      <div>
                        Your contribution today will support thousands of children, families, and seniors during this
                        humanitarian crisis and help facilitate resiliency over the coming months.
                      </div>
                    </div>

                    <div className={cx('has-text-justified')}>
                      {post.item.description || post.item.descriptionShort}
                    </div>
                    {!post.item.marketplaceListingGUID && (
                      <div className={cx('no-sale', 'mt-4')}>This item has been already sold.</div>
                    )}
                    <div className={cx('flexbox', 'wrap')}>
                      {post.item.marketplaceListingGUID && (
                        <>
                          <div className={cx('pricing', 'flex', 'mt-4')}>
                            <div className={cx('')}>
                              <div className={cx('has-text-muted')}>
                                {post.viewMarketPlaceItem?.priceType === 'BestOffer'
                                  ? 'Highest bid'
                                  : 'Donation Amount'}
                              </div>
                              <div className={cx('has-text-weight-bold', 'is-size-2')}>
                                {post?.item?.marketplaceListingUSDAmount
                                  ? `$${post.item.marketplaceListingUSDAmount}`
                                  : ''}
                              </div>
                              {/*<div className={cx('has-text-weight-semibold', 'has-text-muted', 'is-size-5', 'mb-5')}>
                            </div>*/}
                            </div>
                            {post.viewMarketPlaceItem?.priceType === 'BestOffer' && (
                              <div className={cx('flex', 'ml-4')}>
                                <div className={cx('has-text-muted')}>Bid ends</div>
                                <div className={cx('flexbox')}>
                                  <div className={cx('mx-auto')}>
                                    <Timer type={2} date={post.viewMarketPlaceItem.expirationDate as Date} />
                                  </div>
                                </div>
                                <div className={cx('has-text-weight-semibold', 'has-text-muted')}>
                                  {moment(post.viewMarketPlaceItem.expirationDate).format('LL, hA')}
                                </div>
                              </div>
                            )}
                            <PurchaseButton currency={'USD'} />
                          </div>
                        </>
                      )}
                      {post.item.marketplaceListingGUID && (
                        <>
                          <div className={cx('pricing', 'flex', 'mt-4')}>
                            <div>
                              <div className={cx('has-text-muted')}>
                                {post.viewMarketPlaceItem?.priceType === 'BestOffer'
                                  ? 'Highest bid'
                                  : 'Donation Amount'}
                              </div>
                              <div className={cx('has-text-weight-bold', 'is-size-2')}>
                                {post?.item?.marketplaceListingETHAmount
                                  ? `${post.item.marketplaceListingETHAmount.toFixed(3)} ETH`
                                  : ''}
                              </div>
                              {/*<div className={cx('has-text-weight-semibold', 'has-text-muted', 'is-size-5', 'mb-5')}>
                            </div>*/}
                            </div>
                            {post.viewMarketPlaceItem?.priceType === 'BestOffer' && (
                              <div className={cx('flex', 'ml-4')}>
                                <div className={cx('has-text-muted')}>Bid ends</div>
                                <div className={cx('flexbox')}>
                                  <div className={cx('mx-auto')}>
                                    <Timer type={2} date={post.viewMarketPlaceItem.expirationDate as Date} />
                                  </div>
                                </div>
                                <div className={cx('has-text-weight-semibold', 'has-text-muted')}>
                                  {moment(post.viewMarketPlaceItem.expirationDate).format('LL, hA')}
                                </div>
                              </div>
                            )}
                            <PurchaseButton currency={'ETH'} />
                          </div>
                        </>
                      )}
                    </div>

                    {post.item.marketplaceListingPriceType === 'BestOffer' && (
                      <div className={cx('mt-4')}>
                        <div>Sale ends {moment(post.item.marketplaceListingExpirationDate).format('LLL')}</div>
                        <div className={cx('flexbox', 'justify-center')}>
                          <Timer date={post.item.marketplaceListingExpirationDate} type={1} />
                        </div>
                      </div>
                    )}

                    <div className={cx('has-text-left')}>
                      <div className={cx('item-info')}>
                        <div className={cx('has-text-weight-bold')}>Tax ID Information</div>
                        <div>
                          Count On Us is an independent 501(c)3 and all donations are tax-deductible to the extent of
                          IRS regulations. Tax ID: 82-0823845
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx('columns')}>
              <div className={cx('column')}>
                {(post.item.backstoryVideoUrl || post.item.backstoryDescription !== '') && (
                  <div className={cx('section')}>
                    <div className={cx('has-text-weight-semibold', 'has-text-muted', 'is-size-6')}>About the Item</div>

                    {post.item.backstoryVideoUrl && (
                      <div className={cx('collection-card')}>
                        <div>
                          <video
                            className={cx('player', 'mb-2')}
                            src={post.item.backstoryVideoUrl}
                            controls
                            controlsList="nodownload"
                          />
                          <div>{post.item.backstoryDescription}</div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={cx('column')}>
                {post.item.nftBenefits && post.item.nftBenefits.length > 0 && (
                  <div className={cx('section')}>
                    <div className={cx('header')}>Benefits</div>
                    <div className={cx('content')}>
                      <ul className={cx('classic-list', 'check')}>
                        {post.item.nftBenefits?.map((benefit) => (
                          <li>{benefit}</li>
                        ))}
                      </ul>
                      <p className={cx('is-size-7')}>
                        Legal notice: While the creator makes every effort to fulfill these benefits, there is no
                        guarantee that they will complete it and you should NOT make a purchase based on the benefits
                        alone.
                      </p>
                      <div>
                        <img className={cx('signature')} src={merchant.merchant?.signatureImageUrl} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div className={cx('container mt-6')}>
            <div className={cx('section')}>
              <div className={cx('has-text-weight-semibold', 'has-text-muted', 'is-size-6')}>
                More from the Collection
              </div>

              <div className={cx('trance-wrapper')}>
                <div className={cx('trance', 'small')}>
                  {post.collection?.items &&
                    post.collection.items
                      .filter((i, index) => index < 6)
                      .map(
                        (post: Post) =>
                          post.marketListing && (
                            <div key={post.guid} className={cx('trance-card')} onClick={() => viewPiece(post)}>
                              <div className={cx('top')}>
                                {post.postType === 'Image' ? (
                                  <img className={cx('thumbnail')} src={post.contentUrl || post.thumbnailImageUrl} />
                                ) : (
                                  <img className={cx('thumbnail')} src={post.thumbnailImageUrl} />
                                )}
                              </div>
                            </div>
                          )
                      )}
                </div>
              </div>
            </div>
          </div> */}
        </>
      ) : (
        <div className={cx('full-spinner')}>
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Item;
