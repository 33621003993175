import { Post } from 'types';
import { formatNumber } from './formatNumber';
import { ethToUsd, usdToEth } from './rate';

export const getPrice = (post?: Post): [string, string, string] => {
  if (!post?.marketplaceListingGUID && !post?.lastSoldMarketplaceListingGUID) return ['', '', ''];
  if (post.nftCurrency) {
    if (post.nftCurrency === 'USD') {
      return [
        'usd',
        '$' + formatNumber(post.marketplaceListingUSDAmount),
        post.marketplaceListingETHAmount.toFixed(3) + ' ETH'
      ];
    } else {
      return [
        'eth',
        '$' + formatNumber(post.marketplaceListingUSDAmount),
        post.marketplaceListingETHAmount.toFixed(3) + ' ETH'
      ];
    }
  }

  if (post?.marketplaceListingETHAmount) {
    //return `${post.marketplaceListingETHAmount} ETH`;
    return ['eth', '$' + ethToUsd(post.marketplaceListingETHAmount), post.marketplaceListingETHAmount + ' ETH'];
  }

  if (post?.marketplaceListingUSDAmount) {
    //return `$${post.marketplaceListingUSDAmount}`;
    return [
      'usd',
      '$' + formatNumber(post.marketplaceListingUSDAmount),
      usdToEth(post.marketplaceListingUSDAmount) + ' ETH'
    ];
  }

  return ['', '', ''];
};

export const getNftUrl = (post: Post): string | undefined => {
  if (post?.nftImageIpfsUrl) return post.nftImageIpfsUrl;
  if (post?.nftImageIpfsHash) return `https://ipfs.io/ipfs/${post.nftImageIpfsHash}`;
};

export const getSmartContractUrl = (post: Post): string | undefined => {
  if (post?.nftContractAddress) {
    if (process.env.REACT_APP_CHAIN_ID === '1') {
      return `https://etherscan.io/address/${post.nftContractAddress}`;
    } else {
      return `https://rinkeby.etherscan.io/address/${post.nftContractAddress}`;
    }
  }
};
