export const ETH = 2764.14;
//price 8/22 12:28 AM PT

export const ethToUsd = (amount: number) => {
  return (amount * ETH).toFixed(2);
};

export const usdToEth = (amount: number) => {
  return (amount / ETH).toFixed(3);
};
