import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { resetLoginError } from 'state/reducers/sessionReducer';
import { getCurrentUser, getPaymentProfiles, getCryptoWallets } from 'state/reducers/userReducer';
import { fetchMerchants } from 'state/reducers/merchantReducer';
import { RootState } from 'state/store';
import { colorGenerator } from 'utils';

import Login from 'components/Login';
import Modal from 'components/Modal';
import Register from 'components/Register';
import Snackbar from 'components/Snackbar';
import About from 'routes/About';
import AccountBase from 'routes/Account';
import Profile from 'routes/Account/Profile';
import Security from 'routes/Account/Security';
import Wallet from 'routes/Account/Wallet/Wallet';
import CreditCards from 'routes/Account/CreditCards';
import ProfileCollections from 'routes/Account/Collections';
import Collaborator from 'routes/Collaborator';
import Collaborators from 'routes/Collaborators';
import Collection from 'routes/Collection';
import Collections from 'routes/Collections';
import Contact from 'routes/Contact';
import Item from 'routes/Item';
import LoginBase from 'routes/LoginBase';
import Marketplace from 'routes/Marketplace';
import NiftyHome from 'routes/NiftyHome';
import NewNiftyHome from 'routes/NewNiftyHome';
import MarketplaceItem from 'routes/MarketplaceItem';
import PasswordReset from 'components/PasswordReset';
import Questions from 'routes/Questions';
import { setPopupState } from 'state/reducers/userReducer';

import hopeLogo from 'assets/images/couf_logo.png';
import defaultAvatar from 'assets/images/default-avatar.png';
import tiktok from 'assets/images/tiktok-brands.svg';

import { Facebook, Twitter, Instagram } from '@mui/icons-material';

import TermsOfUse from 'routes/TermsOfUse';
import PrivacyPolicy from 'routes/PrivacyPolicy';
import Verification from 'routes/Verification';

import styles from './HomeBase.module.scss';

const cx = classnames.bind(styles);

const HomeBase = () => {
  const { session, user } = useSelector((state: RootState) => ({
    session: state.session,
    user: state.user
  }));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resetLogin = () => {
    dispatch(setPopupState(null));
    dispatch(resetLoginError());
  };

  useEffect(() => {
    if (session.authToken) {
      dispatch(setPopupState(null));
      dispatch(getCurrentUser());
      dispatch(getPaymentProfiles());
      dispatch(getCryptoWallets());
      dispatch(fetchMerchants());
    }
  }, [session.authToken]);

  const popupState = user.popupState;
  return (
    <>
      {popupState !== null && (
        <Modal size="sm" onClose={resetLogin}>
          {popupState === 'login' && <Login type="mod" onChange={(state) => dispatch(setPopupState(state))} />}
          {popupState === 'register' && <Register type="mod" onChange={(state) => dispatch(setPopupState(state))} />}
        </Modal>
      )}

      <Snackbar />

      <div className={cx('navigation', 'py-2')}>
        <div className={cx('container', 'flexbox', 'wrap')}>
          <div className={cx('title', 'flexbox')} onClick={() => navigate('/')}>
            <img className={cx('logo')} src={hopeLogo} />
          </div>
          <div className={cx('map', 'ml-auto')}>
            <ul className={cx('list', 'inline', 'link', 'map-list')}>
              {!session.authToken ? (
                <div className={cx('flexbox', 'account')}>
                  <li className={cx('create-account')} onClick={() => dispatch(setPopupState('register'))}>
                    <button className={cx('button', 'is-outlined', 'clearance')}>Create Account</button>
                  </li>
                  <li onClick={() => dispatch(setPopupState('login'))}>
                    <button className={cx('button', 'clearance')}>Login</button>
                  </li>
                </div>
              ) : (
                user.user && (
                  <li className={cx('account')} onClick={() => navigate('/account')}>
                    <object
                      className={cx('avatar')}
                      data={user.user?.profileImageUrl || defaultAvatar}
                      style={{ backgroundColor: colorGenerator(user.user?.username) }}
                    />
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className={cx('routes')}>
        <Routes>
          <Route path="/collection" element={<Collection />} />
          <Route path="/collection/:guid" element={<Collection />} />
          <Route path="/collections" element={<Collections />} />
          <Route path="/collaborator/:guid" element={<Collaborator />} />
          <Route path="/collaborators" element={<Collaborators />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/marketplace/:guid" element={<MarketplaceItem />} />
          <Route path="/piece/:postGroupGuid/:guid" element={<Item />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<Questions />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/account" element={<AccountBase />}>
            <Route index element={<Profile />} />
            <Route path="profile" element={<Profile />} />
            <Route path="security" element={<Security />} />
            <Route path="wallets" element={<Wallet />} />
            <Route path="credit-cards" element={<CreditCards />} />
            <Route path="collections" element={<ProfileCollections />} />
          </Route>
          <Route path="/login" element={<LoginBase />} />
          <Route path="/verify" element={<PasswordReset />} />
          <Route path="/old" element={<NiftyHome />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/account-verification" element={<Verification />} />
          <Route path="/" element={<NewNiftyHome />} />
        </Routes>
      </div>
      <div className={cx('footer')}>
        {/* <div className={cx('flex')}>
          <div className={cx('has-text-weight-bold')}>Subscribe to receive UNITED24's</div>
          <div>reports, news, and updates.</div>
          <div className={cx('mt-4')}>
            <input className={cx('subscribe')} placeholder="email" />
            <button className={cx('button', 'is-white', 'ml-2')}>SUBCRIBE</button>
          </div>
        </div> */}
        <div className={cx('flex')}>
          <div className={cx('has-text-weight-bold')}>Help us by sharing:</div>
          <div>Post COUNT ON US FOUNDATION in social media.</div>
          <div className={cx('mt-4')}>
            <a
              className={cx('button', 'is-white', 'mr-2', 'mb-2')}
              href="https://twitter.com/COUFoundation"
              target="_blank">
              <Twitter />
              Twitter
            </a>
            <a
              className={cx('button', 'is-white', 'mr-2', 'mb-2')}
              href="https://www.facebook.com/CountOnUsFoundation"
              target="_blank">
              <Facebook />
              Facebook
            </a>
            <a
              className={cx('button', 'is-white', 'mr-2', 'mb-2')}
              href="https://www.tiktok.com/@coufoundation?lang=en"
              target="_blank">
              <img src={tiktok} className={cx('tiktok')} />
              TikTok
            </a>
            <a
              className={cx('button', 'is-white', 'mr-2', 'mb-2')}
              href="https://www.instagram.com/coufoundation/"
              target="_blank">
              <Instagram />
              Instagram
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBase;
