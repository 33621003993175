import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import classnames from 'classnames/bind';

import { LoginRequest } from 'types';
import { loginUser, logout, passwordReset, resendPhoneVerification, setUnverifiedAccount } from 'state/reducers/sessionReducer';

import { RootState } from 'state/store';

import { useNavigate } from 'react-router-dom';
import { setPopupState } from 'state/reducers/userReducer';
import styles from './Login.module.scss';

const cx = classnames.bind(styles);

export type LoginProps = {
  type: 'mod' | 'red';
  onChange: (p: 'register') => void;
};

const Login = (props: LoginProps) => {
  const navigate = useNavigate();
  const { session } = useSelector((state: RootState) => ({
    session: state.session
  }));
  const dispatch = useDispatch();
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [verifyStatus, setVerifyStatus] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginRequest>();

  const onSubmit = (data: LoginRequest) => {
    setVerifyStatus(0);
    dispatch(loginUser(data));
  };

  const onPasswordReset = (data: LoginRequest) => {
    dispatch(passwordReset(data));
  };
  const queryParams = new URLSearchParams(window.location.search);
  const sessionExpired = queryParams.get('session-expired');
  
  useEffect(() => {
    dispatch(logout());
  }, [])
  


  useEffect(() => {
    if (session.authToken) {
      if (props.type === 'red') {
        const lastUrl = localStorage.getItem('lastUrl');
        if (lastUrl && lastUrl !== undefined && !lastUrl.includes('verify')) {
          navigate(lastUrl);
        } else {
          navigate('/');
        }
      }
    }
  }, [session.authToken]);

  useEffect(() => {
    if (
      session.status === 'rejected' &&
      session.loginError === 'Please verify your phone number' &&
      session.errorPayload?.userGUID
    ) {
      dispatch(
        setUnverifiedAccount({
          guid: session.errorPayload.userGUID,
          isRegistered: true,
          isVerified: false
        })
      );
      dispatch(setPopupState(null));
      if (session.guid && session.guid !== undefined) {
        dispatch(resendPhoneVerification(session.errorPayload.userGUID));
      }
      navigate('/account-verification');
    }
  }, [session]);

  useEffect(() => {
    if (session.isPasswordTokenSent) {
      setIsPasswordReset(false);
      setVerifyStatus(1);
    }
  }, [session.isPasswordTokenSent]);

  return (
    <>
      {!isPasswordReset ? (
        <>
          <div className={cx('is-size-3', 'has-text-weight-semibold', 'mb-4')}>Login</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={cx('field')}>
              <label className={cx('label')}>Username</label>
              <div className={cx('control')}>
                <input
                  className={cx('input', { 'is-danger': errors.username })}
                  {...register('username', { required: true })}
                />
              </div>
            </div>

            <div className={cx('field')}>
              <label className={cx('label')}>Password</label>
              <div className={cx('control')}>
                <input
                  type="password"
                  className={cx('input', { 'is-danger': errors.password })}
                  {...register('password', { required: true })}
                />
              </div>
            </div>

            <div className="field">
              <button
                className={cx('button', 'is-fullwidth', {
                  'is-loading': session.status === 'pending'
                })}>
                Log In
              </button>
            </div>

            {verifyStatus === 1 ? (
              <div className={cx('has-text-centered', 'is-size-7')}>
                A reset password email has been sent. If you do not see it, please check your Spam folder.
              </div>
            ) : (
              session.loginError && (
                <div className={cx('has-text-centered', 'has-text-danger', 'is-size-7')}>{session.loginError}</div>
              )
            )}

            {sessionExpired === 'true' && (
              <div className={cx('has-text-centered', 'has-text-danger', 'is-size-7')}>Your session has expired, please login again.</div>
            )}

            <div className={cx('mt-6')}>
              <div className={cx('flexbox')}>
                <div>
                  <div className={cx('is-link')} onClick={() => setIsPasswordReset(true)}>
                    Forgot password?
                  </div>
                </div>
                <div className={cx('ml-auto')}>
                  <div className={cx('is-link')} onClick={() => props.onChange('register')}>
                    Don't have an account? Register here.
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          <div className={cx('is-size-3', 'has-text-weight-semibold', 'mb-4')}>Forgot Password?</div>
          <form onSubmit={handleSubmit(onPasswordReset)}>
            <div className={cx('field')}>
              <label className={cx('label', 'is-size-6')}>Enter the username you use to log into Count On Us:</label>
              <label className={cx('label')}>(An email will be sent to the address we have on file.)</label>
              <div className={cx('control')}>
                <input
                  className={cx('input', { 'is-danger': errors.username })}
                  {...register('username', { required: true })}
                />
              </div>
            </div>

            <div className="field">
              <button
                className={cx('button', 'is-fullwidth', {
                  'is-loading': session.status === 'pending'
                })}>
                Submit
              </button>
            </div>
          </form>

          <div className="field">
            <button
              className={cx('button', 'is-fullwidth', 'is-white', 'mt-4')}
              onClick={() => setIsPasswordReset(false)}>
              Back to Login
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
